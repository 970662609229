import { Component } from '@angular/core';

interface PolicySection {
    title: string;
    content: string[];
}

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent {
    lastUpdated = '09/12/2024';

    policySections: PolicySection[] = [
        {
            title: '2. How We Use Your Information',
            content: [
                'We use the information we collect for the following purposes:',
                '• To Respond to Inquiries: We use your contact information to respond to questions, comments, or requests submitted via our contact form.',
                '• Text Messaging: If you opt-in, we will use your phone number to send you text messages regarding the request you made on our site, our services, updates, or promotional offers.',
            ],
        },
        {
            title: '3. Sharing Your Information',
            content: [
                'We do not sell, trade, rent, or share your personal information with third parties. We do not share your information with any third-party service providers for any purpose.',
                '',
                'Your personal information is used exclusively by speKwo.com to provide and improve our services, ensuring your privacy is maintained.',
                '',
                'SMS consent and phone numbers collected/captured for SMS purposes will not be shared, sold, or disclosed with any third parties, or any other individual for any type of marketing purposes, or affiliate marketing.',
            ],
        },
        {
            title: '4. Cookies and Tracking Technologies',
            content: [
                'Our website may use "cookies" to enhance user experience. You can choose to set your web browser to refuse cookies or to alert you when cookies are being sent.',
                '',
                'Note that some parts of the site may not function properly if cookies are disabled.',
            ],
        },
        {
            title: '5. Data Security',
            content: [
                'We implement reasonable security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.',
                '',
                'However, no method of transmission over the internet or electronic storage is 100% secure.',
            ],
        },
        {
            title: '6. Your Choices and Rights',
            content: [
                '• Opt-Out: You may opt-out of receiving text messages or emails from us at any time by following the unsubscribe instructions provided in the communication or by contacting us directly.',
                '• Access and Correction: You have the right to access and correct your personal information. Contact us to make any changes.',
            ],
        },
        {
            title: '7. Third-Party Links',
            content: [
                'Our website may contain links to third-party websites. We are not responsible for the privacy practices of these external sites.',
                '',
                'We encourage you to read the privacy policies of any linked websites you visit.',
            ],
        },
        {
            title: '8. Changes to This Privacy Policy',
            content: [
                'We reserve the right to update or change our Privacy Policy at any time. Any changes will be posted on this page with an updated revision date.',
            ],
        },
    ];

    contactInfo = {
        email: 'Info@spekwo.com',
        phone: '1-866-646-0200',
        address: '19701 Bethel Church Road, Suite 103-119, Cornelius, NC 28031',
    };

    constructor() {}

    ngOnInit(): void {}
}
