<div class="privacy-policy-container">
    <!-- <div class="logo-container">
      <img src="assets/img/logos/speKwo_logo.png" alt="speKwo Logo" class="logo">
    </div> -->
    
    <h1>Privacy Policy</h1>
    <p class="last-updated">Last Updated: {{ lastUpdated }}</p>
  
    <p class="welcome-text">
      Welcome to speKwo.com ("we," "us," or "our"). We are committed to protecting your personal information and your right to privacy. 
      This Privacy Policy describes how we collect, use, and safeguard your information when you visit our website and use our contact form 
      or opt-in to receive text messages, emails, or phone communication from our office.
    </p>
  
    <p class="consent-text">
      By using our website, you agree to the collection and use of information in accordance with this policy.
    </p>
  
    <div class="policy-section">
      <h2>1. Information We Collect</h2>
      <div class="section-content">
        <h3>Personal Information</h3>
        <p>When you use our contact form or opt-in to receive text messages, we may collect the following personal information:</p>
        <ul>
          <li>Name</li>
          <li>Email Address</li>
          <li>Phone Number</li>
        </ul>
      </div>
      <div class="section-content">
        <h3>Automatically Collected Information</h3>
          <p>We may also collect certain information automatically when you visit our website, such as your IP address, browser type, and pages visited. This information is used for analytical purposes to improve our website's functionality.</p>
      </div>
    </div>
    
    <div *ngFor="let section of policySections" class="policy-section">
      <h2>{{ section.title }}</h2>
      <div class="section-content">
        <span *ngIf="item === 'Automatically Collected Information:'; else normalText" class="bold-large">{{ item }}</span>
        <ng-template #normalText>{{ item }}</ng-template>
        <p *ngFor="let item of section.content">{{ item }}</p>
      </div>
    </div>
  
    <section class="contact-section">
      <h2>9. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy or our data practices, please contact us:</p>
      <div class="contact-details">
        <ul>
          <li><p><strong>Email:</strong> {{ contactInfo.email }}</p>
          </li>
          <li><p><strong>Phone:</strong> {{ contactInfo.phone }}</p>
          </li>
          <li><p><strong>Address:</strong> {{ contactInfo.address }}</p>
          </li>
        </ul>
      </div>  
    </section>
  
    <p class="closing-text">
      By providing a clear and concise Privacy Policy, we aim to build trust with our users and ensure transparency in how we handle personal information.
    </p>
  </div>
  