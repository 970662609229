import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { invoice, invoiceStatus, productContent, productsSku, toastMessages } from 'src/app/components/constants';
import * as _ from "lodash";
import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation } from '@angular/material/stepper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ViewUploadsComponent } from '../view-uploads/view-uploads.component';
import { MatDialog } from '@angular/material/dialog';
import { ProductsService } from '../products.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { common } from 'src/app/components/constants/apiCalls';

@Component({
  selector: 'main-spekwo-form',
  templateUrl: './main-spekwo-form.component.html',
  styleUrls: ['./main-spekwo-form.component.scss']
})
export class MainSpekwoFormComponent {

  spekwoForm: FormGroup;
  addressForm: FormGroup;
  yourRoleForm: FormGroup;
  yourInfoForm: FormGroup;
  quotedForm: FormGroup;
  paymentform: FormGroup;
  copyOfTotalCost: number = 0;
  preListForm: FormGroup;
  additionalPics: FormGroup;
  specificItemForm: FormGroup;
  stepperOrientation: Observable<StepperOrientation>;
  @Input() currentProduct: any;
  productContent = productContent;
  selectedRoleIndex: number = null;
  selectedPayIndex: number = null;
  estimateHourErr = '';
  filesUploadAlert = '';
  isSpecificItem: Boolean = false;
  isFullSpekwo: Boolean = false;
  isPreListItem: Boolean = false;
  isItemCompleted: Boolean = false;
  isPreListItemEnd: Boolean = false;
  picUploadError: string = '';
  base64UrlList: any[] = [];
  isClientPay: Boolean = false;
  isPayingOnMe: Boolean = false;
  progress: number = 0;
  fullSpekwoItems = []
  specificItems = [];
  preListItems = [];
  productReports = new Map();
  isLoading = false;
  isLoadingList = false;
  preListImageFinalList = [];
  markets = [];
  offices: any[] = [];
  firms: any[] = [];
  marketsDropDownMenu = [];
  officesDropDownMenu: any[] = [];
  firmsDropDownMenu: any[] = [];
  couponData = [];
  isSubmitted = false;
  dueDate: any = "";
  orderId: any = -1;
  isCouponApplied = false;
  completeSeEmail = [];
  handler: any = null;
  constructor(
    private _formBuilder: FormBuilder,
    breakpointObserver: BreakpointObserver,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public productsService: ProductsService,
    public router: Router,
    private _matSnackBar: MatSnackBar,
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
  }
  additionaProductlForm: FormGroup;
  options = {
    types: [],
    componentRestrictions: { country: environment.googleAddressCountry }
  };

  @ViewChild("placesRef") placesRef: GooglePlaceDirective;


  ngOnInit() {
    this.additionaProductlForm = this.productsAdditionalForm();
    this.addressForm = this.createAddressFormBuilder();
    this.yourRoleForm = this.createYourRoleFormBuilder();
    this.yourInfoForm = this.createYourInfoFormBuilder();
    this.quotedForm = this.createQuotedFormBuilder();
    this.paymentform = this.createPaymentFormBuilder();
    this.preListForm = this.createPreListForm();
    this.additionalPics = this.createAdditionalPicsForm();
    this.specificItemForm = this.createSpecificItemForm();
    this.fetchMarkets();
    this.refreshCurrentProducts();
    this.addAddiRowsForFullSpekwo();
    this.loadStripe();
  }

  refreshCurrentProducts() {
    this.isFullSpekwo = this.currentProduct.sku === productsSku.fullSpekwo ? true : false;
    this.isSpecificItem = this.currentProduct.sku === productsSku.specificItem ? true : false;
    this.isPreListItem = this.currentProduct.sku === productsSku.preList ? true : false;
    setTimeout(() => {
      this.paymentform.get('totalPrice').setValue(this.currentProduct.productPrice);
    }, 5000);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.currentProduct = changes && changes.currentProduct && changes.currentProduct.currentValue ? changes.currentProduct.currentValue : {};
    this.refreshCurrentProducts();
    this.additionaProductlForm = this.productsAdditionalForm();
    this.addAddiRowsForFullSpekwo();
  }

  public handleAddressChange(address: Address) {
    this.addressForm.get('propertyAddress').setValue(address && address.formatted_address ? address.formatted_address : null);
    this.addressForm.get('latitude').setValue(address.geometry.location.lat().toString());
    this.addressForm.get('longitude').setValue(address.geometry.location.lng().toString());
    this.addressForm.get('street').setValue(address && address.formatted_address && address.formatted_address.length > 0 && address.address_components[1] && address.address_components[1].long_name ? address.address_components[1].long_name : null);
    this.addressForm.get('city').setValue(address && address.formatted_address && address.formatted_address.length > 0 && address.address_components[3] && address.address_components[3].long_name ? address.address_components[3].long_name : null);
    this.addressForm.get('state').setValue(address && address.formatted_address && address.formatted_address.length > 0 && address.address_components[5] && address.address_components[5].long_name ? address.address_components[5].long_name : null);
    this.addressForm.get('country').setValue(address && address.formatted_address && address.formatted_address.length > 0 && address.address_components[6] && address.address_components[6].long_name ? address.address_components[6].short_name : null);
    this.addressForm.get('zip').setValue(address && address.formatted_address && address.formatted_address.length > 0 && address.address_components[7] && address.address_components[7].long_name ? address.address_components[7].long_name : null);
  }

  createAddressFormBuilder() {
    return this._formBuilder.group({
      propertyAddress: ['', Validators.required],
      latitude: [''],
      longitude: [''],
      street: [''],
      city: [''],
      state: [''],
      country: [''],
      zip: ['']
    });
  }

  createYourRoleFormBuilder() {
    return this._formBuilder.group({
      yourRole: ['', Validators.required],
    });
  }

  createYourInfoFormBuilder() {
    return this._formBuilder.group({
      yourRole: ['', Validators.required],
      yourName: ['', Validators.required],
      yourCellNumber: ['', [Validators.required]],
      yourEmail: ['', Validators.required],
      clientName: ['', Validators.required],
      clientCellNumber: ['', Validators.required],
      clientEmail: ['', Validators.required],
      indRole: ['', Validators.required],
      meChecked: [false, Validators.required],
      roleChecked: [false, Validators.required],
      otherChecked: [false, Validators.required],
      smsChecked: [false, Validators.required],
      othersMail: ['', Validators.required],
      marketId: ['', Validators.required],
      firmId: ['', Validators.required],
      officeId: ['', Validators.required],
    });
  }

  createQuotedFormBuilder() {
    return this._formBuilder.group({
      homeInsReport: [''],
      ddraReport: [''],
      wdoReport: [''],
      structEngReport: [''],
      wellReport: [''],
      homeInsChecked: [false],
      ddraChecked: [false],
      wdoReportChecked: [false],
      wellReportChecked: [false],
      structEngChecked: [false],
      isUploadCompleted: ['', Validators.required],
      notes: [''],
    });
  }

  createPaymentFormBuilder() {
    return this._formBuilder.group({
      clientEmail: [''],
      nameOfCard: [''],
      cardNumber: [''],
      expireDate: [''],
      cvcCode: [''],
      promoCode: [''],
      payingOption: ['', Validators.required],
      estimateHour: ['', Validators.required],
      totalPrice: [this.currentProduct.productPrice, Validators.required],
      productSku: ['', Validators.required],
      productName: ['', Validators.required]
    });
  }

  createPreListForm() {
    return this._formBuilder.group({
      homeInsReport: [''],
      homeInsChecked: [false],
      moreThanChecked: [false],
      date: [''],
      name: [''],
      email: [''],
      phone: [''],
    });
  }

  createAdditionalPicsForm() {
    return this._formBuilder.group({
      projectHighlights: [''],
      date: [''],
      name: [''],
      email: [''],
      phone: [''],
    });
  }

  createSpecificItemForm() {
    return this._formBuilder.group({
      item1: ['', Validators.required], item2: [''], item3: [''], item4: [''], item5: [''], item6: [''], item7: [''], item8: [''], item9: [''],
      item10: [''], item11: [''], item12: [''], item13: [''], item14: [''], item15: [''], item16: [''], item17: [''], item18: [''],
      item19: [''], item20: [''], item21: [''], item22: [''], item23: [''], item24: [''], item25: [''], item26: [''], item27: [''],
      item28: [''], item29: [''], item30: [''], item31: [''], item32: [''], item33: [''], item34: [''], item35: [''], item36: [''],
      item37: [''], item38: [''], item39: [''], item40: [''],
    });
  }

  selectYourRole(event, index) {
    this.selectedRoleIndex = index;
    this.yourRoleForm.get('yourRole').setValue(event);
    this.yourInfoForm.get('yourRole').setValue(event)
  }

  quoteEmailChanges(event, type) {

    if (!this.yourInfoForm.value.otherChecked) {
      this.yourInfoForm.get('othersMail').setValue('');
    }

    if (this.yourInfoForm.value.meChecked && this.yourInfoForm.value.yourEmail) {
      this.yourInfoForm.get('roleChecked').setValidators([])
      this.yourInfoForm.get('roleChecked').updateValueAndValidity();
      this.yourInfoForm.get('othersMail').setValidators([])
      this.yourInfoForm.get('othersMail').updateValueAndValidity();
      this.yourInfoForm.get('otherChecked').setValidators([])
      this.yourInfoForm.get('otherChecked').updateValueAndValidity();
      this.completeSeEmail.push(this.yourInfoForm.value.yourEmail);
    } else if (this.yourInfoForm.value.roleChecked && this.yourInfoForm.value.clientEmail) {
      this.yourInfoForm.get('meChecked').setValidators([])
      this.yourInfoForm.get('meChecked').updateValueAndValidity();
      this.yourInfoForm.get('otherChecked').setValidators([])
      this.yourInfoForm.get('otherChecked').updateValueAndValidity();
      this.yourInfoForm.get('othersMail').setValidators([])
      this.yourInfoForm.get('othersMail').updateValueAndValidity();
      this.completeSeEmail.push(this.yourInfoForm.value.clientEmail);
    } else if (this.yourInfoForm.value.otherChecked && this.yourInfoForm.value.othersMail) {
      this.yourInfoForm.get('roleChecked').setValidators([])
      this.yourInfoForm.get('roleChecked').updateValueAndValidity();
      this.yourInfoForm.get('meChecked').setValidators([])
      this.yourInfoForm.get('meChecked').updateValueAndValidity();
      this.completeSeEmail.push(this.yourInfoForm.value.othersMail);
    } else {
      this.yourInfoForm.get('meChecked').setValidators([Validators.required])
      this.yourInfoForm.get('meChecked').updateValueAndValidity();
      this.yourInfoForm.get('roleChecked').setValidators([Validators.required])
      this.yourInfoForm.get('roleChecked').updateValueAndValidity();
      this.yourInfoForm.get('otherChecked').setValidators([Validators.required])
      this.yourInfoForm.get('otherChecked').updateValueAndValidity();
      this.yourInfoForm.get('othersMail').setValidators([Validators.required])
      this.yourInfoForm.get('othersMail').updateValueAndValidity();
    }
    this.completeSeEmail = _.uniq(this.completeSeEmail);
  }

  reportChanges(type, event) {
    if (this.quotedForm.value.homeInsChecked) {
      this.quotedForm.get('homeInsChecked').setValidators([Validators.required])
      this.quotedForm.get('homeInsChecked').updateValueAndValidity();
      this.quotedForm.get('homeInsReport').setValidators([Validators.required])
      this.quotedForm.get('homeInsReport').updateValueAndValidity();
    }
    if (this.quotedForm.value.ddraChecked) {
      this.quotedForm.get('ddraReport').setValidators([Validators.required])
      this.quotedForm.get('ddraReport').updateValueAndValidity();
      this.quotedForm.get('ddraChecked').setValidators([Validators.required])
      this.quotedForm.get('ddraChecked').updateValueAndValidity();
    }
    if (this.quotedForm.value.wdoReportChecked) {
      this.quotedForm.get('wdoReport').setValidators([Validators.required])
      this.quotedForm.get('wdoReport').updateValueAndValidity();
      this.quotedForm.get('wdoReportChecked').setValidators([Validators.required])
      this.quotedForm.get('wdoReportChecked').updateValueAndValidity();
    }
    if (this.quotedForm.value.structEngChecked) {
      this.quotedForm.get('structEngReport').setValidators([Validators.required])
      this.quotedForm.get('structEngReport').updateValueAndValidity();
      this.quotedForm.get('structEngChecked').setValidators([Validators.required])
      this.quotedForm.get('structEngChecked').updateValueAndValidity();
    }
    if (this.quotedForm.value.wellReportChecked) {
      this.quotedForm.get('wellReport').setValidators([Validators.required])
      this.quotedForm.get('wellReport').updateValueAndValidity();
      this.quotedForm.get('wellReportChecked').setValidators([Validators.required])
      this.quotedForm.get('wellReportChecked').updateValueAndValidity();
    }
    if (!this.quotedForm.value.homeInsChecked) {
      this.quotedForm.get('homeInsChecked').setValue(false);
      this.quotedForm.get('homeInsReport').setValue('');
      this.quotedForm.get('homeInsChecked').setValidators([])
      this.quotedForm.get('homeInsChecked').updateValueAndValidity();
      this.quotedForm.get('homeInsReport').setValidators([])
      this.quotedForm.get('homeInsReport').updateValueAndValidity();
    }
    if (!this.quotedForm.value.ddraChecked) {
      this.quotedForm.get('ddraChecked').setValue(false);
      this.quotedForm.get('ddraReport').setValue('');
      this.quotedForm.get('ddraReport').setValidators([])
      this.quotedForm.get('ddraReport').updateValueAndValidity();
      this.quotedForm.get('ddraChecked').setValidators([])
      this.quotedForm.get('ddraChecked').updateValueAndValidity();
    }
    if (!this.quotedForm.value.wdoReportChecked) {
      this.quotedForm.get('wdoReportChecked').setValue(false);
      this.quotedForm.get('wdoReport').setValue('');
      this.quotedForm.get('wdoReport').setValidators([])
      this.quotedForm.get('wdoReport').updateValueAndValidity();
      this.quotedForm.get('wdoReportChecked').setValidators([])
      this.quotedForm.get('wdoReportChecked').updateValueAndValidity();
    }
    if (!this.quotedForm.value.structEngChecked) {
      this.quotedForm.get('structEngChecked').setValue(false);
      this.quotedForm.get('structEngReport').setValue('');
      this.quotedForm.get('structEngReport').setValidators([])
      this.quotedForm.get('structEngReport').updateValueAndValidity();
      this.quotedForm.get('structEngChecked').setValidators([])
      this.quotedForm.get('structEngChecked').updateValueAndValidity();
    }
    if (!this.quotedForm.value.wellReportChecked) {
      this.quotedForm.get('wellReportChecked').setValue(false);
      this.quotedForm.get('wellReport').setValue('');
      this.quotedForm.get('wellReport').setValidators([])
      this.quotedForm.get('wellReport').updateValueAndValidity();
      this.quotedForm.get('wellReportChecked').setValidators([])
      this.quotedForm.get('wellReportChecked').updateValueAndValidity();
    }

    if (!this.quotedForm.value.wellReportChecked &&
      !this.quotedForm.value.structEngChecked &&
      !this.quotedForm.value.wdoReportChecked &&
      !this.quotedForm.value.ddraChecked &&
      !this.quotedForm.value.homeInsChecked) {
      this.quotedForm.get('isUploadCompleted').setValue('');
    }
  }

  addAdditionalDesc(itemValue) {
    return this.formBuilder.group({
      id: [itemValue],
      description: [''],
      image: ['']
    });
  }

  productsAdditionalForm() {
    return this.formBuilder.group({
      bodySideDes: this.formBuilder.group({
        PRE: this.formBuilder.array([this.addAdditionalDesc(this.isPreListItem ? "1" : null)])
      })
    })
  }

  get addProductAdditionals() {
    const control = <FormArray>(<FormGroup>this.additionaProductlForm.get('bodySideDes')).get('PRE');
    return control;
  }

  addMoreAdditionalDesc() {

    if (this.isSpecificItem && this.additionaProductlForm.value.bodySideDes.PRE && this.additionaProductlForm.value.bodySideDes.PRE.length < 40) {
      this.addProductAdditionals.push(this.addAdditionalDesc(null));
    } else if (this.isFullSpekwo) {
      this.addProductAdditionals.push(this.addAdditionalDesc(null));
    } else if (this.isPreListItem && this.additionaProductlForm.value.bodySideDes.PRE && this.additionaProductlForm.value.bodySideDes.PRE.length < 10) {
      this.addProductAdditionals.push(this.addAdditionalDesc(this.additionaProductlForm.value.bodySideDes.PRE.length + 1));
    }

    if (this.additionaProductlForm.value.bodySideDes.PRE && this.additionaProductlForm.value.bodySideDes.PRE.length == 40 && this.isSpecificItem) {
      this.isItemCompleted = true;
    }

    if (this.additionaProductlForm.value.bodySideDes.PRE && this.additionaProductlForm.value.bodySideDes.PRE.length == 10 && this.isPreListItem) {
      this.isPreListItemEnd = true;
    }



  }

  removeAdditionalDesc(index) {
    if (this.additionaProductlForm.value.bodySideDes.PRE && this.additionaProductlForm.value.bodySideDes.PRE.length != 1) {
      this.addProductAdditionals.removeAt(index);
    }

    if (this.additionaProductlForm.value.bodySideDes.PRE && this.additionaProductlForm.value.bodySideDes.PRE.length <= 40 && this.isSpecificItem) {
      this.isItemCompleted = false;
    }

    if (this.additionaProductlForm.value.bodySideDes.PRE && this.additionaProductlForm.value.bodySideDes.PRE.length <= 10 && this.isPreListItem) {
      this.isPreListItemEnd = false;
    }

  }



  onFileSelected(file_type, event) {
    this.productReports.set(file_type, event.target.files[0])

    let isComplete = true;
    if (this.quotedForm.value.homeInsChecked && !this.quotedForm.value.homeInsReport) {
      isComplete = false;
    }
    if (this.quotedForm.value.ddraChecked && !this.quotedForm.value.ddraReport) {
      isComplete = false;
    }
    if (this.quotedForm.value.wdoReportChecked && !this.quotedForm.value.wdoReport) {
      isComplete = false;
    }
    if (this.quotedForm.value.structEngChecked && !this.quotedForm.value.structEngReport) {
      isComplete = false;
    }
    if (this.quotedForm.value.wellReportChecked && !this.quotedForm.value.wellReport) {
      isComplete = false;
    }

    this.quotedForm.get('isUploadCompleted').setValue('completed');

  }

  preListFileSelect(file_type, event) {
    this.productReports.set(file_type, event.target.files[0])
  }


  fileCheckBoxChanges(file_type, event) {
    if (this.preListForm.value.homeInsChecked) {
      this.preListForm.get('homeInsChecked').setValidators([Validators.required])
      this.preListForm.get('homeInsChecked').updateValueAndValidity();
      this.preListForm.get('homeInsReport').setValidators([Validators.required])
      this.preListForm.get('homeInsReport').updateValueAndValidity();
    } else {
      this.preListForm.get('homeInsReport').setValue('')
      this.preListForm.get('homeInsChecked').setValidators([])
      this.preListForm.get('homeInsChecked').updateValueAndValidity();
      this.preListForm.get('homeInsReport').setValidators([])
      this.preListForm.get('homeInsReport').updateValueAndValidity();
    }
  }

  fileInputClick(check_box_control, event) {
    // Open file dialog
    if (this.quotedForm.value[check_box_control]) {
      this.filesUploadAlert = '';
      return true;
    }
    // Do not open file dialog
    else {
      this.filesUploadAlert = this.productContent.billingForm.error.disableUpload;
      this.showToastMessage(this.productContent.billingForm.error.disableUpload, toastMessages.warning)
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }

  filePreListInputClick(check_box_control, event) {
    // Open file dialog
    if (this.preListForm.value[check_box_control]) {
      this.filesUploadAlert = '';
      return true;
    }
    // Do not open file dialog
    else {
      this.filesUploadAlert = this.productContent.billingForm.error.disableUpload;
      this.showToastMessage(this.productContent.billingForm.error.disableUpload, toastMessages.warning)
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }


  onPicsFileSelected(file_type, event, index) {

    if (event.target.files.length <= 2) {
      this.formatImageUploadData(event, index);
      this.picUploadError = '';
      let temp = [];
      for (let i = 0; i < event.target.files.length; i++) {
        var reader = new FileReader();
        reader.onload = async (event: any) => {
          let base64Url = await (<FileReader>event.target).result;
          temp.push(base64Url);
        }
        reader.readAsDataURL(event.target.files[i]);
      }
      setTimeout(() => {
        this.base64UrlList[index] = temp;
      }, 5000);
    } else {
      this.picUploadError = this.productContent.billingForm.error.picUploadError + " " + (index + 1);
      this.showToastMessage(this.picUploadError, toastMessages.warning)
      event.target.value = null;
    }
  }

  // format image upload data
  formatImageUploadData(event, index) {
    let tempArray = this.additionaProductlForm.value.bodySideDes.PRE;
    let currItemNo = tempArray[index].id;

    let tempObj = {
      "itemNo": currItemNo,
      "files": event.target.files
    }

    let filteredIndex = _.findIndex(this.preListImageFinalList, function (o) {
      return o.itemNo.toString() == currItemNo.toString();
    });

    if (filteredIndex != -1) {
      this.preListImageFinalList[filteredIndex] = tempObj;
    }
    else {
      this.preListImageFinalList.push(tempObj);
    }
  }

  // Dialog open
  viewUploadPics(index) {
    return this.dialog.open(ViewUploadsComponent, {
      data: this.base64UrlList[index],
      width: 'calc(100% - 20%)',
      height: 'calc(100% - 20%)',

      panelClass: 'new-template-view',
      direction: 'ltr'
    });
  }

  selectPayingOptions(key, index) {
    this.isCouponApplied = false;
    this.couponData = [];
    this.paymentform.get('totalPrice').setValue(this.copyOfTotalCost);
    this.selectedPayIndex = index;
    this.isLoading = false;
    this.paymentform.get('payingOption').setValue(key);
    if (!this.paymentform.value.estimateHour) {
      this.estimateHourErr = this.productContent.billingForm.error.estimateHourErr;
      this.showToastMessage(this.productContent.billingForm.error.estimateHourErr, toastMessages.warning)
    }
    else if (key == this.productContent.payingOptions[0].key) {
      this.estimateHourErr = '';
      this.isClientPay = true;
      this.isPayingOnMe = false;

      this.paymentform.get('clientEmail').setValidators([Validators.required]);
      this.paymentform.get('clientEmail').updateValueAndValidity();

      this.paymentform.get('nameOfCard').setValidators([]);
      this.paymentform.get('nameOfCard').updateValueAndValidity();

      this.paymentform.get('cardNumber').setValidators([]);
      this.paymentform.get('cardNumber').updateValueAndValidity();

      this.paymentform.get('expireDate').setValidators([]);
      this.paymentform.get('expireDate').updateValueAndValidity();

      this.paymentform.get('cvcCode').setValidators([]);
      this.paymentform.get('cvcCode').updateValueAndValidity();

    } else if (key == this.productContent.payingOptions[1].key) {
      this.estimateHourErr = '';
      this.isClientPay = false;
      this.isPayingOnMe = true;

      this.paymentform.get('clientEmail').setValidators([]);
      this.paymentform.get('clientEmail').updateValueAndValidity();

      this.paymentform.get('nameOfCard').setValidators([]);
      this.paymentform.get('nameOfCard').updateValueAndValidity();

      this.paymentform.get('cardNumber').setValidators([]);
      this.paymentform.get('cardNumber').updateValueAndValidity();

      this.paymentform.get('expireDate').setValidators([]);
      this.paymentform.get('expireDate').updateValueAndValidity();

      this.paymentform.get('cvcCode').setValidators([]);
      this.paymentform.get('cvcCode').updateValueAndValidity();
    }
  }

  async submitOrder() {
    this.isLoading = true;
    let productForms = {
      paymentform: this.paymentform.value,
    }

    let amount = productForms && productForms.paymentform && productForms.paymentform.totalPrice ? Number(productForms.paymentform.totalPrice) : 0;


    if (this.isClientPay || amount < 0.5) {
      this.initialSteps({});
    } else {
      this.pay(amount);
    }

  }

  async initialSteps(tokenRes) {
    if (this.isFullSpekwo) {
      this.fullSpekwoItems = this.additionaProductlForm.value.bodySideDes.PRE;
    } else if (this.isSpecificItem) {
      for (const key in this.specificItemForm.value) {
        if (this.specificItemForm.value[key]) {
          this.specificItems.push(this.specificItemForm.value[key]);
        }
      }
    } else {
      this.preListItems = this.additionaProductlForm.value.bodySideDes.PRE;
    }

    let productForms = {
      addressForm: this.addressForm.value,
      yourRoleForm: this.yourRoleForm.value,
      yourInfoForm: this.yourInfoForm.value,
      quotedForm: this.quotedForm.value,
      paymentform: this.paymentform.value,
      preListForm: this.preListForm.value,
      additionaProductlForm: this.additionaProductlForm.value,
      fullSpekwoItems: this.fullSpekwoItems,
      specificItems: this.specificItems,
      preListItems: this.preListItems,
      created_via: this.isClientPay ? invoice.paymentByClient : invoice.paymentByCard,
      completeSeEmail: this.completeSeEmail
    }
    await this.productsService.formatTheOrdersJson(productForms, {}).then(async (formatJsonRes) => {

      let createOrderPayload = {
        orderContent: JSON.stringify(formatJsonRes),
        orderedDate: "now()",
        createdAt: "now()",
        firstName: productForms && productForms.yourInfoForm && productForms.yourInfoForm.yourName ? productForms.yourInfoForm.yourName : null,
        city: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.city ? productForms.addressForm.city : null,
        state: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.state ? productForms.addressForm.state : null,
        postcode: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.zip ? productForms.addressForm.zip : null,
        address: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.propertyAddress ? productForms.addressForm.propertyAddress : null,
        agentrole: productForms && productForms.yourInfoForm && productForms.yourInfoForm.yourRole ? productForms.yourInfoForm.yourRole : null,
        indrole: productForms && productForms.yourInfoForm && productForms.yourInfoForm.indRole ? productForms.yourInfoForm.indRole : null,
        productId: this.currentProduct && this.currentProduct.productId ? this.currentProduct.productId : null,
        clientphone: productForms && productForms.yourInfoForm && productForms.yourInfoForm.clientCellNumber ? productForms.yourInfoForm.clientCellNumber : null,
        clientemail: productForms && productForms.yourInfoForm && productForms.yourInfoForm.clientEmail ? productForms.yourInfoForm.clientEmail : null,
        duedate: this.hoursBasedCost(),
        notes: productForms && productForms.quotedForm && productForms.quotedForm.notes ? productForms.quotedForm.notes : "",
        marketId: productForms && productForms.yourInfoForm && productForms.yourInfoForm.marketId && productForms.yourInfoForm.marketId != -1 ? productForms.yourInfoForm.marketId : null,
        firmId: productForms && productForms.yourInfoForm && productForms.yourInfoForm.firmId && productForms.yourInfoForm.firmId != -1 ? productForms.yourInfoForm.firmId : null,
        officeId: productForms && productForms.yourInfoForm && productForms.yourInfoForm.officeId && productForms.yourInfoForm.officeId != -1 ? productForms.yourInfoForm.officeId : null,
        couponId: this.couponData.length > 0 && this.couponData[0].couponId ? this.couponData[0].couponId : null,
        statusId: 1,
        contactName: this.additionalPics.value.name ? this.additionalPics.value.name : "",
        contactDate: this.additionalPics.value.date ? moment(this.additionalPics.value.date).format('YYYY-MM-DD') : null,
        contactEmail: this.additionalPics.value.email ? this.additionalPics.value.email : "",
        contactPhone: this.additionalPics.value.phone ? this.additionalPics.value.phone : "",
        projectInfo: this.additionalPics.value.projectHighlights ? this.additionalPics.value.projectHighlights : "",
        haveMorePics: this.preListForm.value.preListForm ? this.preListForm.value.moreThanChecked : false,
        longitude: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.longitude ? parseFloat(productForms.addressForm.longitude) : null,
        latitude: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.latitude ? parseFloat(productForms.addressForm.latitude) : null,
      };

      await this.productsService.createOrder(createOrderPayload).then(async (createOrderRes: any) => {
        this.isSubmitted = true;
        let orderRes = createOrderRes && createOrderRes.data && createOrderRes.data.createOrder && createOrderRes.data.createOrder.order ? createOrderRes.data.createOrder.order : null;
        if (orderRes) {
          this.orderId = orderRes && orderRes.id ? orderRes.id : -1;
          if (this.productReports.get('homeInsReport')) {
            await this.productsService.reportUpload(this.productReports.get('homeInsReport'), orderRes.id, 'HomeInspection').then((reportRes) => {
              console.log(`Home Inspection upload succesfully at ${orderRes.id}`);
            }).catch((reportErr) => {
              console.log(`Home Inspection upload succesfully at ${orderRes.id}`, reportErr);
            });
          }
          if (this.productReports.get('ddraReport')) {
            await this.productsService.reportUpload(this.productReports.get('ddraReport'), orderRes.id, 'DDRA').then((reportRes) => {
              console.log(`DDRA upload succesfully at ${orderRes.id}`);
            }).catch((reportErr) => {
              console.log(`Home Inspection upload failed at ${orderRes.id}`, reportErr);
            });
          }
          if (this.productReports.get('wdoReport')) {
            await this.productsService.reportUpload(this.productReports.get('wdoReport'), orderRes.id, 'WDO').then((reportRes) => {
              console.log(`WDO report upload succesfully at ${orderRes.id}`);
            }).catch((reportErr) => {
              console.log(`WDO report upload failed at ${orderRes.id}`, reportErr);
            });
          }
          if (this.productReports.get('structEngReport')) {
            await this.productsService.reportUpload(this.productReports.get('structEngReport'), orderRes.id, 'StructuralEng').then((reportRes) => {
              console.log(`Structral Engg upload succesfully at ${orderRes.id}`);
            }).catch((reportErr) => {
              console.log(`Structral Engg upload failed at ${orderRes.id}`, reportErr);
            });
          }
          if (this.productReports.get('wellReport')) {
            await this.productsService.reportUpload(this.productReports.get('wellReport'), orderRes.id, 'WellReport').then((reportRes) => {
              console.log(`Well report upload succesfully at ${orderRes.id}`);
            }).catch((reportErr) => {
              console.log(`Well report upload failed at ${orderRes.id} `, reportErr);
            });
          }


          if (this.isFullSpekwo || this.isSpecificItem) {
            this.updateOrder(orderRes, productForms, tokenRes);
          } else {
            await this.preListImageUpload(orderRes.id).then(async (resopnse) => {
              if (resopnse) {
                this.updateOrder(orderRes, productForms, tokenRes);
              } else {
                console.log("pic upload have an issue")
              }
            });
          }

        }
      }).catch((createOrderErr) => {
        console.log("🚀 ~ file: main-spekwo-form.component.ts:577  createOrderErr:", createOrderErr);
        this.isLoading = false;
        this.showToastMessage(toastMessages.orderfailed, toastMessages.failed);
      });

    }).catch((formatJsonError) => {
      console.log("🚀 ~ file: main-spekwo-form.component.ts:420 ~ MainSpekwoFormComponent ~ this.productsService.formatTheOrdersJson ~ formatJsonError:", formatJsonError)
      this.isLoading = false;
      this.showToastMessage(toastMessages.orderFormatErr, toastMessages.failed);
    })

  }

  async updateOrder(orderRes, productForms, tokenRes) {
    let updateProductForms = {
      id: orderRes.id,
      homeInsUrl: this.productReports.get('homeInsReport') && this.productReports.get('homeInsReport').name ? environment.apiHost + "reports" + "/" + orderRes.id + "/" + "HomeInspection" + "/" + escape(this.productReports.get('homeInsReport').name) : "",
      ddraUrl: this.productReports.get('ddraReport') && this.productReports.get('ddraReport').name ? environment.apiHost + "reports" + "/" + orderRes.id + "/" + "DDRA" + "/" + escape(this.productReports.get('ddraReport').name) : "",
      wdoUrl: this.productReports.get('wdoReport') && this.productReports.get('wdoReport').name ? environment.apiHost + "reports" + "/" + orderRes.id + "/" + "WDO" + "/" + escape(this.productReports.get('wdoReport').name) : "",
      structEngUrl: this.productReports.get('structEngReport') && this.productReports.get('structEngReport').name ? environment.apiHost + "reports" + "/" + orderRes.id + "/" + "StructuralEng" + "/" + escape(this.productReports.get('structEngReport').name) : "",
      wellUrl: this.productReports.get('wellReport') && this.productReports.get('wellReport').name ? environment.apiHost + "reports" + "/" + orderRes.id + "/" + "WellReport" + "/" + escape(this.productReports.get('wellReport').name) : "",
      pics: this.additionaProductlForm.value.bodySideDes.PRE
    };

    await this.productsService.formatTheOrdersJson(productForms, updateProductForms).then(async (formatJsonRes) => {
      let updateOrderPayload = {
        orderContent: JSON.stringify(formatJsonRes),
        orderedDate: "now()",
        createdAt: "now()",
        firstName: productForms && productForms.yourInfoForm && productForms.yourInfoForm.yourName ? productForms.yourInfoForm.yourName : null,
        city: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.city ? productForms.addressForm.city : null,
        state: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.state ? productForms.addressForm.state : null,
        postcode: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.zip ? productForms.addressForm.zip : null,
        address: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.propertyAddress ? productForms.addressForm.propertyAddress : null,
        agentrole: productForms && productForms.yourInfoForm && productForms.yourInfoForm.yourRole ? productForms.yourInfoForm.yourRole : null,
        indrole: productForms && productForms.yourInfoForm && productForms.yourInfoForm.indRole ? productForms.yourInfoForm.indRole : null,
        productId: this.currentProduct && this.currentProduct.productId ? this.currentProduct.productId : null,
        homeinsurl: updateProductForms && updateProductForms.homeInsUrl ? updateProductForms.homeInsUrl : null,
        ddraurl: updateProductForms && updateProductForms.ddraUrl ? updateProductForms.ddraUrl : null,
        wdourl: updateProductForms && updateProductForms.wdoUrl ? updateProductForms.wdoUrl : null,
        structengurl: updateProductForms && updateProductForms.structEngUrl ? updateProductForms.structEngUrl : null,
        wellurl: updateProductForms && updateProductForms.wellUrl ? updateProductForms.wellUrl : null,
        clientphone: productForms && productForms.yourInfoForm && productForms.yourInfoForm.clientCellNumber ? productForms.yourInfoForm.clientCellNumber : null,
        clientemail: productForms && productForms.yourInfoForm && productForms.yourInfoForm.clientEmail ? productForms.yourInfoForm.clientEmail : null,
        duedate: this.hoursBasedCost(),
        notes: productForms && productForms.quotedForm && productForms.quotedForm.notes ? productForms.quotedForm.notes : "",
        marketId: productForms && productForms.yourInfoForm && productForms.yourInfoForm.marketId && productForms.yourInfoForm.marketId != -1 ? productForms.yourInfoForm.marketId : null,
        firmId: productForms && productForms.yourInfoForm && productForms.yourInfoForm.firmId && productForms.yourInfoForm.firmId != -1 ? productForms.yourInfoForm.firmId : null,
        officeId: productForms && productForms.yourInfoForm && productForms.yourInfoForm.officeId && productForms.yourInfoForm.officeId != -1 ? productForms.yourInfoForm.officeId : null,
        couponId: this.couponData.length > 0 && this.couponData[0].couponId ? this.couponData[0].couponId : null,
        statusId: 1,
        contactName: this.additionalPics.value.name ? this.additionalPics.value.name : "",
        contactDate: this.additionalPics.value.date ? moment(this.additionalPics.value.date).format('YYYY-MM-DD') : null,
        contactEmail: this.additionalPics.value.email ? this.additionalPics.value.email : "",
        contactPhone: this.additionalPics.value.phone ? this.additionalPics.value.phone : "",
        projectInfo: this.additionalPics.value.projectHighlights ? this.additionalPics.value.projectHighlights : "",
        haveMorePics: this.preListForm.value.preListForm ? this.preListForm.value.moreThanChecked : false,
        longitude: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.longitude ? parseFloat(productForms.addressForm.longitude) : null,
        latitude: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.latitude ? parseFloat(productForms.addressForm.latitude) : null,
      };


      await this.productsService.updateOrder(updateOrderPayload, orderRes.id).then(async (updateRes) => {
        let amount = productForms && productForms.paymentform && productForms.paymentform.totalPrice ? Number(productForms.paymentform.totalPrice) : 0;
        if (this.isClientPay) {
          let clientPayingInvoice = {
            email: productForms && productForms.paymentform && productForms.paymentform.clientEmail ? encodeURIComponent((productForms.paymentform.clientEmail).trim()) : null, //If client invoice email use that one
            name: productForms && productForms.yourInfoForm && productForms.yourInfoForm.yourName ? encodeURIComponent(productForms.yourInfoForm.yourName) : null,
            amount: productForms && productForms.paymentform && productForms.paymentform.totalPrice ? Number(productForms.paymentform.totalPrice) : 0,
            product: productForms && productForms.paymentform && productForms.paymentform.productName ? encodeURIComponent(productForms.paymentform.productName) : '',//"The speKwo",
            phone: productForms && productForms.yourInfoForm && productForms.yourInfoForm.yourCellNumber ? encodeURIComponent(productForms.yourInfoForm.yourCellNumber) : null,// "1234567890",
            city: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.city ? encodeURIComponent(productForms.addressForm.city) : null,//"Salem",
            country: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.country ? encodeURIComponent(productForms.addressForm.country) : null,//"USA",
            address: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.propertyAddress ? encodeURIComponent(productForms.addressForm.propertyAddress) : null,// "Paramanur SPA"
            postal_code: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.zip ? encodeURIComponent(productForms.addressForm.zip) : null,//"636007",
            state: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.state ? encodeURIComponent(productForms.addressForm.state) : null,//"Massachusetts",
            company: "",
            orderId: orderRes.id,// 29775 // orderRes.id
          }

          console.log(clientPayingInvoice, 'data');

          await this.productsService.stripeInvoiceToCustomer(clientPayingInvoice)
            .then(async (cusData: any) => {

              if (cusData && cusData.status == invoiceStatus.completed) {

                this.showToastMessage(toastMessages.invoiceSentToClientSuccess, toastMessages.success);

                let invoicePayload = {
                  orderId: orderRes.id,// 29775 // orderRes.id
                  invoiceObject: cusData && cusData.response ? JSON.stringify(cusData.response) : {},
                  chargeObject: null,
                  invoiceid: cusData && cusData.response && cusData.response.id ? cusData.response.id : {},
                };

                await this.productsService.createInvoiceForCustomer(invoicePayload).then((stripeRes) => {
                  console.log("Stripe invoice stored in DB success", stripeRes)

                }).catch((stripeErr) => {
                  console.log("Stripe invoice stored in DB failed", stripeErr)

                })

              } else {
                this.showToastMessage(toastMessages.invoiceSentToClientFailed, toastMessages.failed);
              }

            })
            .catch(err => {
              console.log("Send Invoice to client email failed", 'err');
              this.showToastMessage(toastMessages.invoiceSentToClientFailed, toastMessages.failed);
            })
        } else if (amount >= 0.5) {
          let paymentByCardPayload = {
            stripeToken: tokenRes && tokenRes.id ? tokenRes.id : null,
            email: productForms && productForms.yourInfoForm && productForms.yourInfoForm.yourEmail ? encodeURIComponent(productForms.yourInfoForm.yourEmail) : null,
            name: productForms && productForms.yourInfoForm && productForms.yourInfoForm.yourName ? encodeURIComponent(productForms.yourInfoForm.yourName) : null,
            phone: productForms && productForms.yourInfoForm && productForms.yourInfoForm.yourCellNumber ? productForms.yourInfoForm.yourCellNumber : null,
            orderId: orderRes.id,
            city: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.city ? encodeURIComponent(productForms.addressForm.city) : null,
            address: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.propertyAddress ? encodeURIComponent(productForms.addressForm.propertyAddress) : null,
            postal_code: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.zip ? productForms.addressForm.zip : null,
            state: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.state ? encodeURIComponent(productForms.addressForm.state) : null,
            amount: productForms && productForms.paymentform && productForms.paymentform.totalPrice ? Number(productForms.paymentform.totalPrice) : 0,
            productName: productForms && productForms.paymentform && productForms.paymentform.productName ? encodeURIComponent(productForms.paymentform.productName) : '',
          };
          await this.productsService.paymentByCard(paymentByCardPayload).then(async (pamentRes: any) => {

            if (pamentRes && pamentRes.response && pamentRes.response.id) {
              await this.showToastMessage(toastMessages.cardSuccess, toastMessages.success);
            }

          }).catch((paymentErr) => {
            console.log("Payment By card having error", paymentErr);
            this.showToastMessage(toastMessages.cardFailed, toastMessages.failed);
          });
        }

        await this.showToastMessage(toastMessages.orderSuccess, toastMessages.success);
        // this.isSubmitted = true;
        this.isLoading = false;
        // this.orderConformEmail(productForms, orderRes, updateProductForms)
        this.orderSuccessEmail(productForms, orderRes, updateProductForms)

        if (this.couponData.length > 0) {
          this.updateUsedLimitInCoupon();
        }
      }).catch((updateErr) => {
        console.log("🚀 ~~ updateErr:", updateErr)
        this.isLoading = false;
        this.showToastMessage(toastMessages.orderfailed, toastMessages.failed);
      })
    }).catch((formatJsonError) => {
      console.log("🚀 ~ file: main-spekwo-form.component.ts:420 ~ MainSpekwoFormComponent ~ this.productsService.formatTheOrdersJson ~ formatJsonError:", formatJsonError)
      this.isLoading = false;
      this.showToastMessage(toastMessages.orderFormatErr, toastMessages.failed);
    });
  }

  // prelist image upload
  async preListImageUpload(orderId) {
    return new Promise(async (resolve, reject) => {
      if (this.isPreListItem && orderId && this.preListImageFinalList && this.preListImageFinalList.length > 0) {
        this.clearImages();
        for (let i = 0; i < this.preListImageFinalList.length; i++) {
          let rowData = this.preListImageFinalList[i];
          if (rowData && rowData.itemNo && rowData.files) {
            for (let j = 0; j < rowData.files.length; j++) {
              let imageData = rowData.files[j];
              if (imageData && imageData.name) {
                let fileName = orderId.toString() + "_" + rowData.itemNo.toString() + "_" + imageData.name.toString();
                await this.productsService.fileUpload(imageData, fileName, rowData.itemNo, orderId).then((imageRes: any) => {
                  if (imageRes && imageRes.status && imageRes.status.toString().toLowerCase() == common.completed) {
                    let tempArray = this.additionaProductlForm.value.bodySideDes.PRE;
                    let filteredIndex = _.findIndex(tempArray, function (o: any) {
                      return o.id.toString() == rowData.itemNo.toString();
                    });
                    if (filteredIndex != -1) {
                      let availableImage = tempArray[filteredIndex]['image'];
                      availableImage = (availableImage ? availableImage + "," : "") + common.uploadedPrelistImage + orderId.toString() + "/" + rowData.itemNo.toString() + "/" + fileName;
                      this.additionaProductlForm.value.bodySideDes.PRE[filteredIndex]['image'] = availableImage;
                    }

                    if (i + 1 === this.preListImageFinalList.length && j + 1 === rowData.files.length) {
                      resolve(true)
                    }

                  }
                })
              }
            }
          }
        }
      } else {
        resolve(true)
      }
    })
  }

  // clear images
  clearImages() {
    for (let i = 0; i < this.additionaProductlForm.value.bodySideDes.PRE.length; i++) {
      this.additionaProductlForm.value.bodySideDes.PRE[i]['image'] = "";
    }
  }

  selectedHourEvent(event) {
    this.copyOfTotalCost = this.currentProduct.productPrice + event.price;
    this.paymentform.get('estimateHour').setValue(event.value);
    this.paymentform.get('totalPrice').setValue(this.currentProduct.productPrice + event.price);
    this.paymentform.get('productSku').setValue(this.currentProduct.sku);
    this.paymentform.get('productName').setValue(this.currentProduct.productName);
    this.isCouponApplied = false;
    this.couponData = [];
  }

  onStepChange(event: any) {
    this.progress = (event.selectedIndex) * 20
  }

  hoursBasedCost() {
    const now = new Date();
    const options = { timeZone: "America/New_York" };
    const estTime = now.toLocaleString("en-US", options);
    let dueDate;
    if (this.paymentform.value.estimateHour === '72 hours') {
      dueDate = moment(estTime)
        .add(72, 'hours')
        .format('MM-DD-YYYY HH:mm:ss');
    }

    else if (this.paymentform.value.estimateHour === '48 hours') {
      dueDate = moment(estTime)
        .add(48, 'hours')
        .format('MM-DD-YYYY HH:mm:ss');
    }

    else if (this.paymentform.value.estimateHour === '24 hours') {
      dueDate = moment(estTime)
        .add(24, 'hours')
        .format('MM-DD-YYYY HH:mm:ss');
    }
    this.dueDate = moment(dueDate).format('MM/DD/YYYY hh:mm A');

    let arr = this.dueDate.split(" ");
    let index = 1;
    let element = "at";

    arr.splice(index, 0, element);
    this.dueDate = arr.join(" ");
    return dueDate;
  }

  // Based on type, Showed toastmessage
  showToastMessage(message: string, toastType: string) {
    this._matSnackBar.open(message, 'OK', {
      verticalPosition: 'top',
      duration: 4000,
      panelClass: [toastType],
    });
  }

  async fetchMarkets() {
    await this.productsService.fetchAllMarkets().then(async (marketList: any) => {

      await this.productsService.formatMarketData(marketList).then((formatMarkets: any) => {
        this.markets = [...formatMarkets];
        this.marketsDropDownMenu = [...formatMarkets];
        this.markets = _.orderBy(this.markets, 'marketName', 'asc');
        this.marketsDropDownMenu = _.orderBy(this.marketsDropDownMenu, 'marketName', 'asc');
        let tempObj = {
          marketId: -1,
          marketName: "Other",
        };
        this.markets.unshift(tempObj);
        this.marketsDropDownMenu.unshift(tempObj);
      }).catch(() => {
        this.markets = [];
      });

    }).catch((marketsError) => {
      console.log("marketsError:", marketsError)
    })
  }

  async fetchFirms() {
    if (this.yourInfoForm.value.marketId) {
      this.isLoadingList = true;
      await this.productsService.fetchAllFirms(this.yourInfoForm.value.marketId).then(async (firmsList: any) => {
        await this.productsService.formatFirmsData(firmsList).then((formatFirms: any) => {
          this.firms = [...formatFirms];
          this.firmsDropDownMenu = [...formatFirms];
          this.firms = _.orderBy(this.firms, 'marketName', 'asc');
          this.firmsDropDownMenu = _.orderBy(this.firmsDropDownMenu, 'marketName', 'asc');
          let tempObj = {
            firmId: -1,
            firmsName: "Other",
          };
          this.firms.unshift(tempObj);
          this.firmsDropDownMenu.unshift(tempObj);
          this.isLoadingList = false;
        }).catch(() => {
          this.firms = [];
          this.isLoadingList = false;
        });
      }).catch((firmsError) => {
        console.log("firmsError:", firmsError)
        this.isLoadingList = false;
      })
    } else {
      this.firms = [];
      this.firmsDropDownMenu = [];
      let tempObj = {
        firmId: -1,
        firmsName: "Other",
      };
      this.firms.push(tempObj);
      this.firmsDropDownMenu.push(tempObj);
    }

  }

  async fetchOffice() {
    if (this.yourInfoForm.value.firmId) {
      this.isLoadingList = true;
      await this.productsService.fetchallOffices(this.yourInfoForm.value.firmId, this.yourInfoForm.value.marketId).then(async (officeList: any) => {
        await this.productsService.formatofficeData(officeList).then((formatOffices: any) => {
          this.offices = [...formatOffices];
          this.officesDropDownMenu = [...formatOffices];
          this.offices = _.orderBy(this.offices, 'marketName', 'asc');
          this.officesDropDownMenu = _.orderBy(this.officesDropDownMenu, 'marketName', 'asc');
          let tempObj = {
            officeId: -1,
            officesName: "Other",
          };
          this.offices.unshift(tempObj);
          this.officesDropDownMenu.unshift(tempObj);
          this.isLoadingList = false;

        }).catch(() => {
          this.offices = [];
          this.isLoadingList = false;
        });

      }).catch((officeError) => {
        console.log("officeError:", officeError);
        this.isLoadingList = false;

      })
    } else {
      this.offices = [];
      this.officesDropDownMenu = [];
      let tempObj = {
        officeId: -1,
        officesName: "Other",
      };
      this.offices.push(tempObj);
      this.officesDropDownMenu.push(tempObj);
    }

  }


  async applyPromoCode() {
    this.isLoading = true;
    let payload = {
      couponCode: encodeURIComponent(this.paymentform.value.promoCode.trim()),
      expireDate: "now()"
    }
    await this.productsService.fetchCouponsByName(payload).then((couponRes: any) => {
      let couponData = couponRes && couponRes.data && couponRes.data.allCoupons && couponRes.data.allCoupons.nodes && couponRes.data.allCoupons.nodes.length > 0 ? couponRes.data.allCoupons.nodes : [];
      if (couponData.length > 0 && couponData[0].usageLimit === couponData[0].totalLimit) {
        this.showToastMessage(toastMessages.couponsSold, toastMessages.warning);
        this.isLoading = false;
      }
      else if (couponData.length > 0) {
        let discountAmount = couponData[0].couponAmount ? couponData[0].couponAmount : 0;
        if (this.paymentform.value.totalPrice >= discountAmount) {
          let afterDiscount = this.paymentform.value.totalPrice - discountAmount;
          this.paymentform.get('totalPrice').setValue(afterDiscount);
        } else {
          this.paymentform.get('totalPrice').setValue(0);
        }
        this.isLoading = false;
        this.couponData = couponData;
        this.isCouponApplied = true;
        this.showToastMessage(toastMessages.codeApplied + " $" + discountAmount + " " + "Discount", toastMessages.success);
      } else {
        this.showToastMessage(toastMessages.promoCodeNotAvaialble, toastMessages.warning);
        this.isLoading = false;
      }
    }).catch((couponErr) => {
      console.log("couponErr:", couponErr);
      this.showToastMessage(toastMessages.applyCodeError, toastMessages.failed);
      this.isLoading = false;
    })
  }

  async updateUsedLimitInCoupon() {
    let couponId = this.couponData.length > 0 && this.couponData[0].couponId ? this.couponData[0].couponId : -1;
    let existingLimit = this.couponData.length > 0 && this.couponData[0].usageLimit ? this.couponData[0].usageLimit : 0;
    let updatedLimit = existingLimit + 1;
    await this.productsService.updateUsageLimit(couponId, updatedLimit).then((updateRes: any) => {
    }).catch((couponUpdateErr) => {
      console.log("couponErr:", couponUpdateErr);
    })
  }

  async orderConformEmail(productForms, orderRes, updateProductForms) {
    let payload = {
      orderId: orderRes.id,
      propertyAddress: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.propertyAddress ? encodeURIComponent(productForms.addressForm.propertyAddress) : "Not Found",
      yourRole: productForms && productForms.yourInfoForm && productForms.yourInfoForm.yourRole ? productForms.yourInfoForm.yourRole : "Not Found",
      yourName: productForms && productForms.yourInfoForm && productForms.yourInfoForm.yourName ? encodeURIComponent(productForms.yourInfoForm.yourName) : "Not Found",
      yourCellNumber: productForms && productForms.yourInfoForm && productForms.yourInfoForm.yourCellNumber ? productForms.yourInfoForm.yourCellNumber : "Not Found",
      yourEmail: productForms && productForms.yourInfoForm && productForms.yourInfoForm.yourEmail ? encodeURIComponent(productForms.yourInfoForm.yourEmail) : "Not Found",
      clientName: productForms && productForms.yourInfoForm && productForms.yourInfoForm.clientName ? productForms.yourInfoForm.clientName : "Not Found",
      clientCellNumber: productForms && productForms.yourInfoForm && productForms.yourInfoForm.clientCellNumber ? productForms.yourInfoForm.clientCellNumber : "Not Found",
      clientEmail: productForms && productForms.yourInfoForm && productForms.yourInfoForm.clientEmail ? productForms.yourInfoForm.clientEmail : "Not Found",
      indRole: productForms && productForms.yourInfoForm && productForms.yourInfoForm.indRole ? productForms.yourInfoForm.indRole : "Not Found",
      homeInsReport: updateProductForms && updateProductForms.homeInsUrl ? updateProductForms.homeInsUrl : "Not Found",
      ddraReport: updateProductForms && updateProductForms.ddraUrl ? updateProductForms.ddraUrl : "Not Found",
      wdoReport: updateProductForms && updateProductForms.wdoUrl ? updateProductForms.wdoUrl : "Not Found",
      structEngReport: updateProductForms && updateProductForms.structEngUrl ? updateProductForms.structEngUrl : "Not Found",
      wellReport: updateProductForms && updateProductForms.wellUrl ? updateProductForms.wellUrl : "Not Found",
      nameOfCard: this.paymentform.value && this.paymentform.value.nameOfCard ? this.paymentform.value.nameOfCard : "Not Found",
      promoCode: this.paymentform.value.promoCode ? this.paymentform.value.promoCode : "You are not applied promo code",
      totalPrice: productForms && productForms.paymentform && productForms.paymentform.totalPrice ? Number(productForms.paymentform.totalPrice) : "Not Found",
      productName: productForms && productForms.paymentform && productForms.paymentform.productName ? encodeURIComponent(productForms.paymentform.productName) : "Not Found",
      lastCardNumber: this.paymentform.value && this.paymentform.value.cardNumber ? "**** **** **** **** " + this.paymentform.value.cardNumber.slice(12) : "Not Found",
      invoiceEmail: productForms && productForms.paymentform && productForms.paymentform.clientEmail ? encodeURIComponent((productForms.paymentform.clientEmail).trim()) : "You are paid at a time of order so you did not receive payment invoice by email",
      paymentMethod: this.isClientPay ? "Client Paying" : "Payment by card at a time of order",
      termsAccept: "Yes",
      isClientPayingEnable: this.isClientPay ? "Yes" : "No",
      solReceiveEmail: [
        productForms && productForms.yourInfoForm && productForms.yourInfoForm.yourEmail ? productForms.yourInfoForm.yourEmail : "Not Found",
        productForms && productForms.yourInfoForm && productForms.yourInfoForm.clientEmail ? productForms.yourInfoForm.clientEmail : "Not Found",
        productForms && productForms.yourInfoForm && productForms.yourInfoForm.othersMail ? productForms.yourInfoForm.othersMail : "Not Found",
      ].join(","),
      orderedDate: moment(new Date()).format('MM-DD-YYYY HH:mm:ss')
    };
    await this.productsService.sentEmail(payload).then((emailRes) => {
      console.log("🚀 ~ file: main-spekwo-form.component.ts:1079 ~ MainSpekwoFormComponent ~ awaitthis.productsService.sentEmail ~ emailRes:", emailRes)
    }).catch((emailErr) => {
      console.log("Sent email failed");
    });
  }

  async orderSuccessEmail(productForms, orderRes, updateProductForms) {
    let payload = {
      propertyAddress: productForms && productForms.addressForm && productForms.addressForm && productForms.addressForm.propertyAddress ? encodeURIComponent(productForms.addressForm.propertyAddress) : "Not Found",
      yourEmail: productForms && productForms.yourInfoForm && productForms.yourInfoForm.yourEmail ? encodeURIComponent(productForms.yourInfoForm.yourEmail) : "Not Found",
      productName: productForms && productForms.paymentform && productForms.paymentform.productName ? encodeURIComponent(productForms.paymentform.productName) : "Not Found",
      orderedDate: moment(new Date()).format('MM-DD-YYYY HH:mm:ss'),
      estimateHour: productForms && productForms.paymentform && productForms.paymentform.estimateHour ? productForms.paymentform.estimateHour : '',
      dueDate: this.dueDate,
      yourName: productForms && productForms.yourInfoForm && productForms.yourInfoForm.yourName ? encodeURIComponent(productForms.yourInfoForm.yourName) : "Not Found",
    };
    await this.productsService.sentOrderSubmitEmail(payload).then((emailRes) => {
      console.log("🚀 ~ file: main-spekwo-form.component.ts:1079 ~ MainSpekwoFormComponent ~ awaitthis.productsService.sentEmail ~ emailRes:", emailRes)
    }).catch((emailErr) => {
      console.log("Sent email failed");
    });
  }

  async onSelect(event, type) {
    if (type === 'localMarketName') {
      this.yourInfoForm.get('firmId').setValue('');
      this.yourInfoForm.get('officeId').setValue('');
      await this.fetchFirms();
    } else if (type === 'firmName') {
      this.yourInfoForm.get('officeId').setValue('');
      await this.fetchOffice();
    }
  }

  filter(val: string, dropDownList, propertyName) {
    return dropDownList.filter(option =>
    ((option[propertyName] && option[propertyName].toLowerCase().indexOf(val.toLowerCase()) !== -1) ||
      (option[propertyName] && option[propertyName].toLowerCase().indexOf(val.toLowerCase()) !== -1))
    );
  }

  addAddiRowsForFullSpekwo() {
    if (this.isFullSpekwo) {
      this.addMoreAdditionalDesc()
      this.addMoreAdditionalDesc();
      this.addMoreAdditionalDesc();
    }
  }

  quoteFormNext() {
    if (this.isSpecificItem && !this.specificItemForm.valid) {
      this.showToastMessage(toastMessages.specificNote, toastMessages.warning);
    }
  }

  pay(amount: any): void {

    var handler = (<any>window).StripeCheckout.configure({
      key: environment.stripePublicKey,
      locale: 'auto',
      token: async function (token: any) {
        paymentStripe(token)
      }
    })

    const paymentStripe = (tokenRes) => {

      if (tokenRes && tokenRes.id) {
        this.initialSteps(tokenRes);
      } else if (tokenRes && tokenRes.response && tokenRes.response.jsonBody && tokenRes.response.jsonBody.error) {
        this.showToastMessage(tokenRes.response.jsonBody.error.message, toastMessages.failed);
        this.isLoading = false;
      }
    }

    handler.open({
      name: 'speKwo Order checkout',
      description: 'Payment Process initiated',
      amount: amount * 100,
      email: this.yourInfoForm.value.yourEmail
    });

  }

  loadStripe() {

    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      s.onload = () => {
        this.handler = (<any>window).StripeCheckout.configure({
          key: environment.stripePublicKey,
          locale: 'auto',
          token: function (token: any) {
            alert('Payment Success!!');
          }
        });
      }

      window.document.body.appendChild(s);
    }
  }

}


