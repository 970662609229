<div class="container">
    <p
        class="s1"
        style="
            padding-top: 70px;
            padding-left: 5pt;
            text-indent: 0pt;
            text-align: left;
        "
    >
        SpeKwo, LLC. (“SpeKwo”, “we”, “us”, “our”), GRANTS YOU ACCESS TO ITS
        WEBSITE, ACCESSIBLE VIA SPEKWO.COM (THE “SITE”) CONDITIONED ON YOUR
        ACCEPTANCE OF THE TERMS, CONDITIONS AND NOTICES CONTAINED HEREIN (THE
        “TERMS OF USE”) TOGETHER WITH OUR PRIVACY POLICY AND ANY OTHER DOCUMENTS
        REFERRED TO HEREIN. FOR THE PURPOSES OF THESE TERMS OF USE, “SERVICES”
        MEANS ANY SERVICES OR PRODUCTS PROVIDED BY SPEKWO THROUGH THE SITE; AND
        “CONTENT” MEANS, COLLECTIVELY, ANY TEXT, IMAGES, GRAPHICS, SOFTWARE,
        SOURCE CODE, APPS, SPECIFICATIONS, AUDIO FILES, VIDEOS, ARTICLES,
        TRADEMARKS, LOGOS AND OTHER INFORMATION OR CONTENT AVAILABLE THROUGH THE
        SITE THAT ARE NOT SUBMISSIONS (AS THAT TERM IS DEFINED BELOW), INCLUDING
        BUT NOT LIMITED TO THE DESIGN, STRUCTURE, SELECTION, COORDINATION,
        EXPRESSION, AND ARRANGEMENT OF SUCH CONTENT.
    </p>
    <ol id="l1">
        <li data-list-text="1.">
            <p
                class="s1"
                style="
                    padding-top: 13pt;
                    padding-left: 41pt;
                    text-indent: -17pt;
                    text-align: left;
                "
            >
                Acceptance of Terms.
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <p
                class="s1"
                style="padding-left: 5pt; text-indent: 0pt; text-align: left"
            >
                PLEASE READ THESE TERMS OF USE AND SPEKWO’S PRIVACY POLICY
                CAREFULLY. BY CLICKING A REGISTRATION OR NEW ACCOUNT SUBMISSION
                BUTTON, OR BY OTHERWISE USING THE SERVICES OR ACCESSING ANY
                CONTENT PROVIDED BY US THROUGH THE SERVICES, OR USING THE SITE,
                ON ANY COMPUTER, MOBILE PHONE, TABLET, CONSOLE OR OTHER DEVICES,
                YOU AGREE TO THESE TERMS OF USE, AND YOU CONSENT TO THE
                COLLECTION, USE AND DISCLOSURE OF INFORMATION AS DESCRIBED IN
                THESE TERMS OF USE AND OUR PRIVACY POLICY. IF YOU DO NOT AGREE
                TO THESE TERMS OF USE AND THE TERMS AND CONDITIONS OF OUR
                PRIVACY POLICY, YOU MUST EXIT THE SITE IMMEDIATELY AND
                DISCONTINUE ANY USE OF THE SITE, THE SERVICES AND ANY OF ITS
                CONTENT. YOUR USE OF THE SITE AND SERVICES SHALL BE DEEMED TO BE
                YOUR AGREEMENT TO ABIDE BY EACH OF THE TERMS SET FORTH BELOW.
                SPEKWO CAN CHANGE THESE TERMS OF USE AT ANY TIME BY POSTING
                UPDATED TERMS OF USE ON THE SITE OR BY SENDING REGISTERED USERS
                AN EMAIL NOTICE OF THE CHANGES. IF ANY MODIFICATION IS
                UNACCEPTABLE TO YOU, YOU SHALL CEASE USING THE SITE AND THE
                SERVICES. IF YOU DO NOT CEASE USING THESE SITE, YOU WILL BE
                DEEMED TO HAVE ACCEPTED THE CHANGE. These Terms of Use
            </p>
            <p
                class="s1"
                style="
                    padding-left: 5pt;
                    text-indent: 0pt;
                    line-height: 13pt;
                    text-align: left;
                "
            >
                do not alter in any way the terms or conditions of any other
                agreement you may have
            </p>
            <p
                class="s1"
                style="
                    padding-left: 5pt;
                    text-indent: 0pt;
                    line-height: 14pt;
                    text-align: left;
                "
            >
                with SpeKwo or its affiliates for other products or services.
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <p
                class="s1"
                style="padding-left: 5pt; text-indent: 0pt; text-align: left"
            >
                Our Privacy Policy, which is incorporated into these Terms of
                Use by this reference, further describes the collection and use
                of information on this Site. Notwithstanding anything else to
                the contrary contained in these Terms of Use, SpeKwo’s use of
                any personally identifiable information (name, etc.) you provide
                via the Site shall be governed by our Privacy Policy. For
                further information regarding SpeKwo’s protection of your
                personal information, please refer to our
                <span class="s2">privacy policy</span>.
            </p>
            <p
                class="s1"
                style="
                    padding-top: 4pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: left;
                "
            >
                You are expected to check this page from time to time to take
                notice of any changes SpeKwo made, as they are binding on you.
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <p
                class="s1"
                style="padding-left: 5pt; text-indent: 0pt; text-align: left"
            >
                These Terms of Use were updated as of January 1, 2024.
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
        </li>
        <li data-list-text="2.">
            <p
                class="s1"
                style="padding-left: 41pt; text-indent: -17pt; text-align: left"
            >
                Use and Restrictions.
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <p
                class="s1"
                style="padding-left: 5pt; text-indent: 0pt; text-align: left"
            >
                The Site may be accessed and used only by individuals who can
                form legally binding contracts under applicable laws, who are 18
                years of age or older, and who are not barred from using the
                Sites under applicable laws. By agreeing to these Terms of Use,
                you represent and warrant that: (a) you are at least 18 years of
                age; (b) you have not previously been suspended or removed from
                the Services; and (c) your registration and your use of the
                Site, the Services and its Content is in compliance with all
                applicable laws and regulations. To access some features of the
                Services, you may be required to register for an account and
                agree to a product’s terms, to the extent applicable to that
                service, which may be incorporated herein or available on a
                separate SpeKwo site. When you register for an account you will
                be asked to provide us with some information about yourself,
                such as email address, phone number, or other contact
                information. You agree that the information you provide is
                accurate and that you will keep it accurate and up-to- date at
                all times.
            </p>
            <p
                class="s1"
                style="
                    padding-top: 14pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: left;
                "
            >
                BY USING THE SITE, SERVICES AND CONTENT, YOU AGREE NOT TO:
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <ul id="l2">
                <li data-list-text="">
                    <p
                        class="s1"
                        style="
                            padding-left: 41pt;
                            text-indent: -18pt;
                            text-align: left;
                        "
                    >
                        reproduce, modify, distribute, display or otherwise
                        provide access to, create derivative works from,
                        decompile, disassemble, or reverse engineer any portion
                        of the Site or the Services;
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        class="s1"
                        style="
                            padding-top: 5pt;
                            padding-left: 41pt;
                            text-indent: -18pt;
                            text-align: left;
                        "
                    >
                        provide/post/authorize a link to any of the Site or
                        Services from a third-party website;
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        class="s1"
                        style="
                            padding-top: 5pt;
                            padding-left: 41pt;
                            text-indent: -18pt;
                            text-align: left;
                        "
                    >
                        remove or modify any copyright or other intellectual
                        property notices that appear in the Site or the
                        Services;
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        class="s1"
                        style="
                            padding-top: 5pt;
                            padding-left: 41pt;
                            text-indent: -18pt;
                            text-align: left;
                        "
                    >
                        use the Site or the Services in any way that is
                        unlawful, or harms SpeKwo, its service providers,
                        suppliers, affiliates, or any other user;
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        class="s1"
                        style="
                            padding-top: 5pt;
                            padding-left: 41pt;
                            text-indent: -18pt;
                            text-align: left;
                        "
                    >
                        use the Site or Services in any way to discriminate
                        against any individual or class of individuals protected
                        under federal, state or local laws, or which may have a
                        discriminatory impact against any individual or class of
                        individuals, or which otherwise promotes illegal, racist
                        or discriminatory activities or outcomes;
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        class="s1"
                        style="
                            padding-top: 5pt;
                            padding-left: 41pt;
                            text-indent: -18pt;
                            text-align: left;
                        "
                    >
                        distribute or post spam or other unsolicited messages,
                        chain letters, pyramid schemes, or similar
                        communications through the Site or Services;
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        class="s1"
                        style="
                            padding-top: 5pt;
                            padding-left: 41pt;
                            text-indent: -18pt;
                            text-align: left;
                        "
                    >
                        impersonate another person, misrepresent your
                        affiliation with another person or entity, or make any
                        representation to any third party under false pretenses;
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        class="s1"
                        style="
                            padding-top: 4pt;
                            padding-left: 41pt;
                            text-indent: -18pt;
                            text-align: left;
                        "
                    >
                        reproduce, publicly display, or otherwise make
                        accessible on or through any other website, application,
                        or service any reviews, ratings, or profile information
                        about any data or Content available through the Site and
                        the Services, except as explicitly permitted by us for a
                        particular portion of the Services;
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        class="s1"
                        style="
                            padding-top: 5pt;
                            padding-left: 41pt;
                            text-indent: -18pt;
                            text-align: left;
                        "
                    >
                        upload invalid data, viruses, worms, or other software
                        agents to the Site or the Services;
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        class="s1"
                        style="
                            padding-top: 5pt;
                            padding-left: 41pt;
                            text-indent: -18pt;
                            text-align: left;
                        "
                    >
                        post, reproduce, publicly display, or otherwise make
                        accessible any content, which we, in our sole judgement
                        and discretion, consider illegal, offensive or
                        objectionable including without limitation content that
                        harasses, discriminates, demeans, threatens or
                        disparages any individual or class of individuals;
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        class="s1"
                        style="
                            padding-top: 5pt;
                            padding-left: 41pt;
                            text-indent: -18pt;
                            text-align: left;
                        "
                    >
                        interfere with, or compromise the system integrity or
                        security of the Site or Services, or otherwise bypass
                        any measures we may use to prevent or restrict access to
                        the Site or Services;
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        class="s1"
                        style="
                            padding-top: 5pt;
                            padding-left: 41pt;
                            text-indent: -18pt;
                            text-align: left;
                        "
                    >
                        conduct automated queries (including screen and database
                        scraping, spiders, robots, crawlers, bypassing “captcha”
                        or similar precautions, or any other automated activity
                        with the purpose of obtaining information from the
                        Services) on the Site or Services;
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        class="s1"
                        style="
                            padding-top: 5pt;
                            padding-left: 41pt;
                            text-indent: -17pt;
                            text-align: left;
                        "
                    >
                        access or use any of the Services to develop competitive
                        products or services; or
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        class="s1"
                        style="
                            padding-top: 5pt;
                            padding-left: 41pt;
                            text-indent: -17pt;
                            text-align: left;
                        "
                    >
                        attempt to, or permit or encourage any third party to,
                        do any of the above.
                    </p>
                </li>
            </ul>
        </li>
        <li data-list-text="3.">
            <p
                class="s1"
                style="
                    padding-top: 5pt;
                    padding-left: 41pt;
                    text-indent: -17pt;
                    text-align: left;
                "
            >
                Copyright
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <p
                class="s1"
                style="padding-left: 5pt; text-indent: 0pt; text-align: left"
            >
                The Services, Contents and Site as well as the selection and
                arrangement thereof, are the sole property of SpeKwo and/or its
                licensors and are protected by patent, copyright, trademark and
                other intellectual property laws and may not be used except in
                accordance with these Terms of Use or with SpeKwo’s express
                written consent.
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
        </li>
        <li data-list-text="4.">
            <p
                class="s1"
                style="padding-left: 41pt; text-indent: -17pt; text-align: left"
            >
                Trademark
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <p
                class="s1"
                style="padding-left: 5pt; text-indent: 0pt; text-align: left"
            >
                All trademarks, service marks, trade names, logos and trade
                dress, whether registered or unregistered (collectively the
                “Marks”) that appear on the Services and the Site are
                proprietary to SpeKwo. You may not display or reproduce the
                Marks other than with the prior written consent of SpeKwo, and
                you may not remove or otherwise modify any trademark notices
                from any Content. The Marks are protected by trademark, trade
                dress, copyright, and various other intellectual property rights
                and unfair competition laws.
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <p
                class="s1"
                style="padding-left: 5pt; text-indent: 0pt; text-align: left"
            >
                In addition, all page headers, custom graphics, button icons,
                and scripts are trademarks, service marks, trade names and/or
                trade dress of SpeKwo or their respective owners, and may not be
                copied, imitated, or used, in whole or in part, without the
                prior written permission of SpeKwo.
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
        </li>
        <li data-list-text="5.">
            <p
                class="s1"
                style="padding-left: 41pt; text-indent: -17pt; text-align: left"
            >
                Intellectual Property.
            </p>
            <p
                class="s1"
                style="
                    padding-top: 10pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: left;
                "
            >
                The Site, Services and Content are owned and operated by SpeKwo.
                The user interfaces, design, information, data, code, products,
                software, graphics, and all other elements of the Site, Services
                and Content (the “SpeKwo’ Materials”) that we provide are
                protected by intellectual property and other laws and are the
                property of SpeKwo or SpeKwo’s third-party licensors. Except as
                expressly allowed by these Terms of Use, you may not make use of
                the SpeKwo’s Materials, and SpeKwo reserve all rights to the
                SpeKwo´s Materials, Contents and Services not granted expressly
                in these Terms of Use.
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
        </li>
        <li data-list-text="6.">
            <p
                class="s1"
                style="padding-left: 41pt; text-indent: -17pt; text-align: left"
            >
                Feedback and Information
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <p
                class="s1"
                style="padding-left: 5pt; text-indent: 0pt; text-align: left"
            >
                If you choose to provide input and suggestions regarding the
                Site or the Services, including related to any of the SpeKwo’
                Materials (“Feedback”), then you hereby grant us an
                unrestricted, perpetual, irrevocable, non-exclusive, fully-paid,
                royalty-free right to use the Feedback in any manner and for any
                purpose, including the improve the Services or create other
                products and services. You hereby allow SpeKwo, and you must
                ensure that you have authorization to allow SpeKwo, to use any
                data and information obtained from your use of the Site or the
                Services to provide, without limitation, estimates, bids,
                schedules, or any additional value add products and services.
            </p>
        </li>
        <li data-list-text="7.">
            <p
                class="s1"
                style="
                    padding-top: 14pt;
                    padding-left: 41pt;
                    text-indent: -17pt;
                    text-align: left;
                "
            >
                Electronic Communications
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <p
                class="s1"
                style="padding-left: 5pt; text-indent: 0pt; text-align: left"
            >
                You consent to receive electronic communications from SpeKwo
                either in the form of email sent to you at the email address you
                provide via the Site or by communications posted on the Site for
                any purpose. You acknowledge and agree that any electronic
                communication in the form of such email or posting on the Site
                shall satisfy any legal requirement that such communication be
                in writing.
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <p
                class="s1"
                style="padding-left: 5pt; text-indent: 0pt; text-align: left"
            >
                You agree that SpeKwo may use and/or disclose information
                consistent with its Privacy Policy.
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
        </li>
        <li data-list-text="8.">
            <p
                class="s1"
                style="padding-left: 41pt; text-indent: -17pt; text-align: left"
            >
                Modifications
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <p
                class="s1"
                style="padding-left: 5pt; text-indent: 0pt; text-align: left"
            >
                Any Content offered or posted on the Site is subject to
                modification or discontinuation at any time and from time to
                time without notice or obligation to you. Without limiting the
                foregoing, SpeKwo reserves the right, in its sole discretion, to
                charge fees for the use or purchase of, or access to, any
                products, services or Content offered through the Site, at any
                time and from time to time.
            </p>
        </li>
        <li data-list-text="9.">
            <p
                class="s1"
                style="
                    padding-top: 4pt;
                    padding-left: 41pt;
                    text-indent: -17pt;
                    text-align: left;
                "
            >
                Indemnification
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <p
                class="s1"
                style="padding-left: 5pt; text-indent: 0pt; text-align: left"
            >
                You agree to defend, indemnify and hold harmless SpeKwo, its
                affiliates and their respective directors, officers, employees
                and agents against any losses, liabilities, claims, expenses
                (including attorney’s fees) as a result of or related to: (i)
                any submission that you provide through the Site or your access
                to or use of the Site; (ii) your breach or alleged breach of
                these Terms of Use; (iii) your violation of any third-party
                right, including without limitation, any intellectual property
                right, publicity, confidentiality, property or privacy right;
                (iv) your violation of any laws, rules, regulations, codes,
                statutes, ordinances or orders of any governmental and
                quasi-governmental authorities, including, without limitation,
                all regulatory, administrative and legislative authorities; or
            </p>
            <p
                class="s1"
                style="
                    padding-left: 5pt;
                    text-indent: 0pt;
                    line-height: 14pt;
                    text-align: left;
                "
            >
                (v) any misrepresentation made by you.
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
        </li>
        <li data-list-text="10.">
            <p
                class="s1"
                style="padding-left: 41pt; text-indent: -17pt; text-align: left"
            >
                Links to Third Party Websites
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <p
                class="s1"
                style="padding-left: 5pt; text-indent: 0pt; text-align: left"
            >
                The Site may contain links to websites and other resources
                operated by third parties other than SpeKwo. Such links are
                provided solely as a convenience to you. SpeKwo does not control
                such websites, and is not responsible for the content, products,
                services or information offered by any third parties. The
                inclusion of links to such websites on the Site does not imply
                any endorsement of any website or the content, products or
                services offered, advertised, endorsed or promoted by any third
                party, or of any company or person. If you decide to access any
                third party websites or acquire any third party products or
                services, you do so entirely at your own risk, and you may be
                subject to the terms and conditions and the privacy policies
                imposed by such third parties.
            </p>
        </li>
        <li data-list-text="11.">
            <p
                class="s1"
                style="
                    padding-top: 14pt;
                    padding-left: 41pt;
                    text-indent: -17pt;
                    text-align: left;
                "
            >
                Warranties and Disclaimer
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <p
                class="s1"
                style="padding-left: 5pt; text-indent: 0pt; text-align: left"
            >
                THE SITE, SERVICES, SUBMISSIONS AND ANY CONTENT PROVIDED VIA THE
                SITE, INCLUDING LINKS, ARE PROVIDED ON AN “AS IS” AND “AS
                AVAILABLE” BASIS WITH NO WARRANTY OF ANY KIND. TO THE MAXIMUM
                EXTENT PERMITTED BY LAW, SPEKWO DISCLAIMS ALL REPRESENTATIONS,
                WARRANTIES AND CONDITIONS, EXPRESS OR IMPLIED, WITH RESPECT TO
                THE SITE, SUBMISSIONS, THE CONTENT AND ANY PRODUCTS OR SERVICES,
                INCLUDING, WITHOUT LIMITATION, WARRANTIES OR CONDITIONS OF
                MERCHANTABLE QUALITY, MERCHANTABILITY, DURABILITY, FITNESS FOR A
                PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, FREEDOM FROM
                ERRORS, OMISSIONS, COMPUTER VIRUSES OR OTHER MALICIOUS OR
                UNAUTHORIZED CODE OR PROGRAMS, AND IMPLIED WARRANTIES OR
                CONDITIONS ARISING FROM COURSE OF DEALING OR COURSE OF
                PERFORMANCE. IN ADDITION, SPEKWO DOES NOT REPRESENT OR WARRANT
                THAT THE SITE, SUBMISSIONS, CONTENT OR ANY PRODUCTS, SERVICES OR
                ANY OTHER INFORMATION ACCESSIBLE VIA THE SITE IS SECURE,
                ACCURATE, COMPLETE OR CURRENT OR THAT ANY PARTICULAR PRODUCTS OR
                INVENTORY WILL BE AVAILABLE AT ANY PARTICULAR TIME. AS A
                CONDITION OF YOUR USE
            </p>
            <p
                class="s1"
                style="
                    padding-top: 4pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: left;
                "
            >
                OF THE SITE, YOU WARRANT TO SPEKWO THAT YOU WILL NOT USE THE
                SITE FOR ANY PURPOSE THAT IS UNLAWFUL OR PROHIBITED BY THESE
                TERMS OF USE.
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <p
                class="s1"
                style="padding-left: 5pt; text-indent: 0pt; text-align: left"
            >
                Nothing in these Terms of Use shall affect your legal rights
                under applicable consumer laws.
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <p
                class="s1"
                style="padding-left: 5pt; text-indent: 0pt; text-align: left"
            >
                IN ADDITION, IF A SERVICE OFFERING BECOMES UNAVAILABLE FOR
                WHATEVER REASON AFTER AN ORDER IS ACCEPTED, SPEKWO MAY TERMINATE
                THE ORDER BY GIVING YOU WRITTEN NOTICE. IN SUCH EVENT, SPEKWO
                WILL CONTACT YOU TO ARRANGE FOR A REFUND IN THE EVENT YOU HAVE
                PREPAID.
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
        </li>
        <li data-list-text="12.">
            <p
                class="s1"
                style="padding-left: 41pt; text-indent: -17pt; text-align: left"
            >
                Limitation of Liability
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <p
                class="s1"
                style="padding-left: 5pt; text-indent: 0pt; text-align: left"
            >
                SPEKWO AND ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
                SUPPLIERS, LICENSORS AND THIRD PARTY PARTNERS WILL NOT BE LIABLE
                FOR ANY INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL, EXEMPLARY,
                MULTIPLIED OR CONSEQUENTIAL DAMAGES, LOST PROFITS OR REVENUES OR
                FAILURE TO REALIZE EXPECTED SAVINGS, OR ANY DAMAGES WHATSOEVER,
                WHETHER OR NOT SUCH PARTY HAS BEEN PREVIOUSLY ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES, WHETHER UNDER CONTRACT, TORT
                (INCLUDING NEGLIGENCE) OR ANY OTHER THEORY, INCLUDING, WITHOUT
                LIMITATION, ARISING OUT OF OR IN CONNECTION WITH ANY LOSS OR
                OTHER DAMAGES IN CONNECTION WITH ANY UNAVAILABILITY OR
                NONPERFORMANCE OF THE SITE, ERRORS, OMISSIONS, VIRUSES AND
                MALICIOUS CODE, UNLESS SUCH LOSS OR DAMAGES ARE CAUSED DIRECTLY
                BY SPEKWO’S FRAUD, RECKLESSNESS, GROSS NEGLIGENCE. THESE
                LIMITATIONS WILL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL
                PURPOSE OF ANY LIMITED REMEDY. SPEKWO WILL NOT BE LIABLE FOR ANY
                DAMAGES OF ANY KIND ARISING OUT OF OR RELATING TO THE USE OF OR
                THE INABILITY TO USE THE SITE OR THE SERVICES, THE SITE’S
                CONTENT OR EXTERNAL LINKS, INCLUDING BUT NOT LIMITED TO DAMAGES
                CAUSED BY OR RELATED TO ERRORS, OMISSIONS, INTERRUPTIONS,
                DEFECTS, DELAY IN OPERATION OR TRANSMISSION, OR ANY COMPUTER
                VIRUS OR FAILURE. REGARDLESS OF THE PREVIOUS SENTENCES, IF
                SPEKWO IS FOUND TO BE LIABLE, SUCH LIABILITY IS LIMITED TO THE
                GREATER OF THE ACTUAL TOTAL AMOUNT RECEIVED BY SPEKWO FROM YOU
                OR $100.
            </p>
            <p style="padding-top: 1pt; text-indent: 0pt; text-align: left">
                <br />
            </p>
            <p
                class="s1"
                style="padding-left: 5pt; text-indent: 0pt; text-align: left"
            >
                You agree that any claim you may have arising out of or related
                to your relationship with SpeKwo must be filed within one year
                after such claim arose; otherwise, your claim is permanently
                barred.
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
        </li>
        <li data-list-text="13.">
            <p
                class="s1"
                style="padding-left: 41pt; text-indent: -17pt; text-align: left"
            >
                Governing Law
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <p
                class="s1"
                style="padding-left: 5pt; text-indent: 0pt; text-align: left"
            >
                All litigation, court proceedings, lawsuits, court hearings and
                other hearings or actions initiated in connection with the Sites
                and/or the Content must and will be venued
            </p>
            <p
                class="s1"
                style="
                    padding-top: 4pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: left;
                "
            >
                exclusively in the state of New Mexico. These Terms of Use and
                the relationship between you and SpeKwo will be governed by the
                laws of the State of New Mexico, without giving effect to
                principles of conflict of laws of any jurisdiction.
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
        </li>
        <li data-list-text="14.">
            <p
                class="s1"
                style="padding-left: 41pt; text-indent: -17pt; text-align: left"
            >
                Severability
            </p>
        </li>
    </ol>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <p class="s1" style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        If any provision of these Terms of Use is held to be invalid, illegal or
        unenforceable in any respect under any applicable law or rule in any
        jurisdiction, such invalidity, illegality or unenforceability will not
        affect the effectiveness or validity of any provision in any other
        jurisdiction, and these Terms of Use will be reformed, construed and
        enforced in such jurisdiction as if such provision had never been
        contained herein. Any headings or titles herein are for convenience
        only.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <ol id="l3">
        <li data-list-text="13.">
            <p
                class="s1"
                style="padding-left: 41pt; text-indent: -17pt; text-align: left"
            >
                Entire Agreement
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <p
                class="s1"
                style="padding-left: 5pt; text-indent: 0pt; text-align: left"
            >
                These Terms of Use and our Privacy Policy constitute the entire
                agreement between you and SpeKwo with respect to the Site, and
                they supersede all prior or contemporaneous communications and
                proposals, whether electronic, oral or written, between you and
                SpeKwo with respect to the Site.
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
        </li>
        <li data-list-text="14.">
            <p
                class="s1"
                style="padding-left: 41pt; text-indent: -17pt; text-align: left"
            >
                How to contact SpeKwo
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <p
                class="s1"
                style="padding-left: 5pt; text-indent: 0pt; text-align: left"
            >
                If you wish to contact us to update your information, please
                feel free to contact us:
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <ul id="l4">
                <li data-list-text="">
                    <p
                        class="s1"
                        style="
                            padding-left: 41pt;
                            text-indent: -17pt;
                            text-align: left;
                        "
                    >
                        by telephone at
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        style="
                            padding-top: 5pt;
                            padding-left: 25pt;
                            text-indent: -0pt;
                            text-align: left;
                        "
                    >
                        <a
                            href="mailto:info@speKwo.com"
                            style="
                                color: #494a5a;
                                font-family: Tahoma, sans-serif;
                                font-style: normal;
                                font-weight: normal;
                                text-decoration: none;
                                padding-right: 5px;
                                font-size: 12pt;
                            "
                            target="_blank"
                            >by email at </a
                        ><a
                            href="mailto:info@speKwo.com"
                            class="s3"
                            target="_blank"
                            >info&#64;speKwo.com</a
                        >
                    </p>
                </li>
            </ul>
        </li>
    </ol>
    <h1
        style="
            padding-top: 3pt;
            padding-left: 164pt;
            text-indent: -102pt;
            text-align: center;
        "
    >
        PRIVACY POLICY AND TERMS &amp; CONDITIONS
    </h1>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        SpeKwo.com takes the issue of privacy seriously. We let the user decide
        what information to give us, and explain exactly what is done with it.
    </p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        The Privacy Policy and Terms of Service were last modified and are
        effective as of January 1, 2024
    </p>
    <h2
        style="
            padding-left: 5pt;
            text-indent: 0pt;
            line-height: 21pt;
            text-align: left;
        "
    >
        Privacy Policy
    </h2>
    <p
        style="
            padding-top: 14pt;
            padding-left: 5pt;
            text-indent: 0pt;
            text-align: left;
        "
    >
        SpeKwo, LLC. (“SpeKwo.com”, “we”, “our”, or “us”) provides this Privacy
        Policy to inform you of our policies and procedures regarding the
        collection, use and disclosure of personal information we receive via
        our Service (as defined in the Terms of Service below) from users of our
        Service. This Privacy Policy may be updated from time to time. We will
        notify you of any material changes by posting the new Privacy Policy on
        our Website. Your use of the Service following any such notice will
        signify and constitute your assent to and acceptance of such revised
        Privacy Policy. Unless otherwise defined in this Privacy Policy,
        capitalized terms used herein have the same meanings as in our Terms of
        Service.
    </p>
    <p style="padding-top: 5pt; text-indent: 0pt; text-align: left"><br /></p>
    <ol id="l5">
        <li data-list-text="1.">
            <h2
                style="padding-left: 22pt; text-indent: -17pt; text-align: left"
            >
                Information Collection and Use
            </h2>
            <p
                style="
                    padding-top: 15pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: justify;
                "
            >
                For the purposes of this Privacy Policy, “Personal Information”
                means information that can be used to uniquely identify you,
                such as your name, telephone number, address, and e-mail
                address.
            </p>
            <p style="padding-top: 5pt; text-indent: 0pt; text-align: left">
                <br />
            </p>
            <p style="padding-left: 5pt; text-indent: 0pt; text-align: justify">
                Personal Information We Collect
            </p>
            <p style="padding-top: 5pt; text-indent: 0pt; text-align: left">
                <br />
            </p>
            <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
                When you interact with us, we may ask you to supply us with
                Personal Information. For example, we may collect Personal
                Information when you:
            </p>
            <p style="padding-top: 5pt; text-indent: 0pt; text-align: left">
                <br />
            </p>
            <ul id="l6">
                <li data-list-text="">
                    <p
                        style="
                            padding-left: 77pt;
                            text-indent: -18pt;
                            text-align: left;
                        "
                    >
                        Use our Service, including our Website, or use/download
                        any of our Apps;
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        style="
                            padding-left: 77pt;
                            text-indent: -18pt;
                            text-align: left;
                        "
                    >
                        Request quotes, support, information, or access to our
                        information products and services;
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        style="
                            padding-left: 77pt;
                            text-indent: -17pt;
                            line-height: 14pt;
                            text-align: left;
                        "
                    >
                        Download Content, software or a software update;
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        style="
                            padding-left: 77pt;
                            text-indent: -18pt;
                            text-align: left;
                        "
                    >
                        Subscribe to newsletters, promotional emails, alerts,
                        updates or other materials; or
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        style="
                            padding-left: 77pt;
                            text-indent: -17pt;
                            line-height: 14pt;
                            text-align: left;
                        "
                    >
                        Register for webinars or other content offers
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        style="
                            padding-left: 77pt;
                            text-indent: -17pt;
                            text-align: left;
                        "
                    >
                        Contact us.
                    </p>
                    <h2
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: justify;
                        "
                    >
                        Our Use of Your Personal Information
                    </h2>
                    <p
                        style="
                            padding-top: 3pt;
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        We mainly use your Personal Information, either alone or
                        in combination with other information, so that we can
                        provide, enhance and personalize our Service and our
                        marketing efforts.
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        The following are examples of a variety of ways that we
                        may use your Personal Information. We may use your
                        Personal Information:
                    </p>
                    <p
                        style="
                            padding-top: 4pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        style="
                            padding-left: 77pt;
                            text-indent: -17pt;
                            text-align: left;
                        "
                    >
                        To provide you with the best customer experience
                        possible;
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        style="
                            padding-left: 77pt;
                            text-indent: -17pt;
                            text-align: left;
                        "
                    >
                        To provide you with a pricing report
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        style="
                            padding-left: 77pt;
                            text-indent: -18pt;
                            text-align: left;
                        "
                    >
                        To communicate with you regarding updates, offers,
                        orders, and promotions;
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        style="
                            padding-left: 77pt;
                            text-indent: -18pt;
                            text-align: left;
                        "
                    >
                        To help us develop, customize, deliver, support and
                        improve our services, products, content, and
                        advertising;
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        style="
                            padding-left: 77pt;
                            text-indent: -17pt;
                            line-height: 14pt;
                            text-align: left;
                        "
                    >
                        To help authenticate any account you create with us;
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        style="
                            padding-left: 77pt;
                            text-indent: -17pt;
                            text-align: left;
                        "
                    >
                        To administer promotions;
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        style="
                            padding-left: 77pt;
                            text-indent: -18pt;
                            text-align: left;
                        "
                    >
                        To serve advertisements on our Website, through
                        e-marketing or collaboration with Third-party providers
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        style="
                            padding-left: 77pt;
                            text-indent: -18pt;
                            text-align: left;
                        "
                    >
                        To protect the rights or property of SpeKwo.com or our
                        users, and prevent fraud and other prohibited or illegal
                        activities;
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        style="
                            padding-left: 77pt;
                            text-indent: -17pt;
                            line-height: 14pt;
                            text-align: left;
                        "
                    >
                        To protect the security of our products, services, and
                        business;
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        style="
                            padding-left: 77pt;
                            text-indent: -18pt;
                            text-align: left;
                        "
                    >
                        For our internal purposes (such as auditing, data
                        analysis, and research to improve our products,
                        services, and communications); and
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        style="
                            padding-left: 77pt;
                            text-indent: -17pt;
                            line-height: 14pt;
                            text-align: left;
                        "
                    >
                        Otherwise, as disclosed to you at the point of
                        collection.
                    </p>
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        If you decide at any time that you no longer wish to
                        receive communications from us, please follow any
                        unsubscribe instructions that may be provided in our
                        communications to you, or update your user preferences
                        via e-mail subscription management tools on our Website.
                        Please note that we may use your Personal Information to
                        contact you with important notifications, such as
                        notices with respect to purchases, safety, or changes to
                        our terms, conditions, and policies; you may not opt out
                        of these notifications.
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                    <h2
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        Collection and Use of Non-Identifying Information
                    </h2>
                    <p
                        style="
                            padding-top: 15pt;
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        We may collect, use, transfer, and disclose information
                        in a form that does not personally identify you
                        (“Non-Identifying Information”) for any purpose. Non-
                        Identifying Information can include certain Personal
                        Information that has been de- identified; that is,
                        information that has been rendered anonymous. We and/or
                        our third party service providers obtain Non-Identifying
                        Information about you from information that you provide
                        us, either separately or together with your Personal
                        Information. We and/or our third party service providers
                        also automatically collect certain Non-Identifying
                        Information from you when you access our Service.
                    </p>
                    <p
                        style="
                            padding-top: 3pt;
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        Certain Non-Identifying Information about you would be
                        considered a part of your Personal Information if it
                        were combined with other identifiers in a way that
                        enables you to be identified. But the same pieces of
                        information are considered Non- Identifying Information
                        when they are taken alone or combined only with other
                        Non- Identifying information. We may combine your
                        Personal Information with Non- Identifying Information
                        and aggregate it with information collected from other
                        users or sources to attempt to provide you with a better
                        experience, to improve the quality and value of our
                        products and services, and to analyze and understand how
                        our products and services are used. We may also use the
                        combined information without aggregating it to serve you
                        specifically.
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                    <h2
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        Non-Identifying Information We Automatically Collect
                    </h2>
                    <p
                        style="
                            padding-top: 15pt;
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        When you access the Service via a web browser, App, or
                        other client, we and our third-party service providers
                        may use a variety of technologies, now and hereafter
                        devised, that automatically record certain usage
                        information. This information may include your Internet
                        Protocol address (and other unique identifiers for the
                        particular device you use to access the Internet),
                        browser type, your web or application request, your
                        interaction with our Service, the webpage or services
                        feature you were using before you came to our Service,
                        pages of our Website that you visit, information you
                        search for via our Service, access times and dates, and
                        other similar information. We use this information for a
                        variety of purposes, including analyzing and enhancing
                        our products and services, and to help personalize our
                        Service for you. We do not treat this information as
                        Personal Information (except to the extent required by
                        local law), although if we combine it with the Personal
                        Information you provide, we will treat the combined
                        information as Personal Information. We and our
                        third-party service providers may automatically collect
                        Non-Identifying Information using various methods,
                        including, without limitation, the following methods.
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        Cookies. A cookie is a small data file transferred to
                        your computer (or other device) when it is used to
                        access our Service. Cookies may be used for many
                        purposes, including to enable certain features of our
                        Service, to better understand how you interact with our
                        Service and to monitor aggregate usage by visitors and
                        online traffic routing. You may be able to instruct your
                        browser, by changing its options, to stop accepting
                        cookies or to prompt you before accepting a cookie from
                        the online services you visit. If you do not accept
                        cookies, however, you may not be able to use all
                        portions of our Service or all functionality of our
                        Service.
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        Click-Through URLs. In some of our e-mail
                        communications, we use a “click-through URL” linked to
                        content on our websites. When users click one of these
                        URLs, they pass through a separate web server before
                        arriving at the destination page on our Website. We may
                        track this click-through data to help us determine
                        interest in
                    </p>
                    <p
                        style="
                            padding-top: 3pt;
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        particular topics and measure the effectiveness of our
                        customer communications. If you prefer not to be tracked
                        in this way, you should not click text or graphic links
                        in the email messages.
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                    <h2
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        Third Party Advertising Technology
                    </h2>
                    <p
                        style="
                            padding-top: 15pt;
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        Our Service may use third parties such as network
                        advertisers to serve advertisements and may use traffic
                        measurement services to analyze traffic. Third- party ad
                        serving enables advertisements to be targeted to you for
                        products and services in which you might be interested.
                    </p>
                    <p
                        style="
                            padding-top: 4pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        Third party ad network providers, the advertisers, the
                        sponsors and/or traffic measurement services may
                        themselves set and access their own cookies and other
                        technologies on your personal computer and/or device and
                        track certain behavioral information regarding users of
                        your personal computer and/or mobile device.
                    </p>
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        These third party cookies and other technologies are set
                        to, among other things: (a) help deliver advertisements
                        to you that you might be interested in; (b) prevent you
                        from seeing the same advertisements too many times; and
                        (c) understand the usefulness to you of the
                        advertisements that have been delivered to you. Note
                        that any images (or any other parts of a web page)
                        served by third parties in association with third party
                        cookies may serve as web beacons, which enable third
                        parties to carry out the previously described
                        activities. Third party cookies, web beacons, and other
                        third party technologies are governed by each third
                        party’s specific privacy policy, not this one.
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
            </ul>
        </li>
        <li data-list-text="2.">
            <h2
                style="padding-left: 24pt; text-indent: -18pt; text-align: left"
            >
                Information Sharing and Disclosure
            </h2>
            <p
                style="
                    padding-top: 15pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: left;
                "
            >
                Except as described in this Privacy Policy, we will not share
                your Personal Information with other companies or individuals
                outside of SpeKwo.com unless we have received your consent.
                Non-Identifying Information may be provided to third parties for
                marketing, advertising, or other uses.
            </p>
            <p style="padding-top: 5pt; text-indent: 0pt; text-align: left">
                <br />
            </p>
            <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
                Our Service Providers and Other Third Parties
            </h2>
            <p
                style="
                    padding-top: 15pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: left;
                "
            >
                We may engage third party companies and individuals to perform
                services on our behalf (e.g., without limitation, software
                maintenance services, advertising serving technologies, e-mail
                service providers, delivery services, database management, web
                analytics, construction repairs, and other services). These
                third parties may have access to your Personal Information. If
                they do, this access is provided so that they may perform these
                tasks on our behalf and they are not authorized by us to
                otherwise use or disclose your Personal Information, except to
                the extent required by law. In addition, if you choose to
                participate in certain of our programs or to
            </p>
            <p
                style="
                    padding-top: 3pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: left;
                "
            >
                receive certain content we make available (e.g., webinar
                programs), we may share your Personal Information with
                participating sponsors, content providers, and related vendors
                in connection with such programs and content.
            </p>
            <p style="padding-top: 5pt; text-indent: 0pt; text-align: left">
                <br />
            </p>
            <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
                Compliance with Laws, Legal Process and Authorities
            </h2>
            <p
                style="
                    padding-top: 15pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: left;
                "
            >
                We cooperate with government and law enforcement officials and
                private parties to enforce and comply with the law. We will
                disclose any information about you to government or law
                enforcement officials or private parties as we, in our sole
                discretion, believe necessary or appropriate to respond to
                claims and legal process (including but not limited to
                subpoenas), to protect the property and rights of us or a third
                party, to protect the safety of the public or any person, or to
                prevent or stop activity we may consider to be, or to pose a
                risk of being, illegal, unethical or legally actionable.
            </p>
            <p style="padding-top: 5pt; text-indent: 0pt; text-align: left">
                <br />
            </p>
            <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
                Data Transfer
            </h2>
            <p
                style="
                    padding-top: 15pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: left;
                "
            >
                SpeKwo.com processes personal information on our servers. Your
                Personal Information may be transferred to, and used, in
                locations outside of your state, province, country or other
                governmental jurisdiction where the privacy laws may not be as
                protective as those in your jurisdiction. You hereby consent to
                such transfer and use in all respects.
            </p>
            <p style="padding-top: 5pt; text-indent: 0pt; text-align: left">
                <br />
            </p>
            <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
                Business Transfers
            </h2>
            <p
                style="
                    padding-top: 15pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: left;
                "
            >
                Circumstances may arise where, whether for strategic or other
                business reasons, we may sell, transfer or otherwise disclose
                some or all of our assets, including your Personal Information,
                in connection with a merger, acquisition, reorganization or sale
                of assets or in the event of bankruptcy. It’s SpeKwo.com’s
                practice to seek appropriate protection for information in these
                types of transactions.
            </p>
            <p style="padding-top: 5pt; text-indent: 0pt; text-align: left">
                <br />
            </p>
        </li>
        <li data-list-text="3.">
            <h2
                style="padding-left: 23pt; text-indent: -18pt; text-align: left"
            >
                General.
            </h2>
        </li>
    </ol>
    <p
        style="
            padding-top: 15pt;
            padding-left: 5pt;
            text-indent: 0pt;
            text-align: left;
        "
    >
        Changing or Deleting Your Information
    </p>
    <p style="padding-top: 5pt; text-indent: 0pt; text-align: left"><br /></p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        SpeKwo.com has implemented technology, management processes and policies
        to help maintain data accuracy. SpeKwo.com makes good faith efforts to
        provide individuals with reasonable access to Personal Information that
        they have provided to SpeKwo.com and the reasonable ability to review
        and correct it or ask for deletion, as applicable, if it is not
        otherwise required to be retained by law or for legitimate business
        purposes. To view and change Personal Information that you
    </p>
    <p
        style="
            padding-top: 3pt;
            padding-left: 5pt;
            text-indent: 0pt;
            text-align: left;
        "
    >
        <a href="mailto:info@SpeKwo.com" class="a" target="_blank"
            >have directly provided to our Service, you can follow the
            instructions on the Website, or contact SpeKwo.com in writing at </a
        >infoSpeKwo.com. Please note we may retain server/backup copies of all
        such data, and that we may decline to process change or deletion
        requests that we deem to be unreasonable, or require disproportionate
        technical effort, or jeopardize the privacy of others.
    </p>
    <p style="padding-top: 5pt; text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        Security
    </h2>
    <p
        style="
            padding-top: 15pt;
            padding-left: 5pt;
            text-indent: 0pt;
            text-align: left;
        "
    >
        We employ appropriate security measures to protect your Personal
        Information from unauthorized access, alteration, disclosure, or
        destruction. These security measures include administrative, technical,
        and physical precautions to safeguard your Personal Information against
        loss, theft, and misuse. Please be advised, however, that while we
        strive to protect your Personal Information and privacy, we cannot
        guarantee or warrant the security of any information you disclose or
        transmit to us online and are not responsible for the theft, destruction
        or inadvertent disclosure of your personally identifiable information.
    </p>
    <p style="padding-top: 5pt; text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        Guarantee of Pricing
    </h2>
    <p
        style="
            padding-top: 15pt;
            padding-left: 5pt;
            text-indent: 0pt;
            text-align: left;
        "
    >
        It is important you understand that the service provided by SpeKwo.com
        is purely for estimation and negotiation purposes. We in no way
        guarantee that the prices we estimate for repairs on your report are the
        same that you will end up paying for that specific repair. Our pricing
        is based on average cost for a similar repair in your area, and due to
        unknown conditions or underlying issues that we are not privvy to,
        actual repair cost may be substantially higher.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        Product Download
    </h2>
    <p
        style="
            padding-top: 15pt;
            padding-left: 5pt;
            text-indent: 0pt;
            text-align: left;
        "
    >
        Our products are currently delivered by Internet download only. After
        your purchase has been approved we will process your order. Orders are
        typically processed within seventy two (72) hours but could take longer
        complete. Once your order has been processed we will send you a
        confirmation email using the email address you provided on our order
        form.
    </p>
    <p style="padding-top: 5pt; text-indent: 0pt; text-align: left"><br /></p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        This email will serve as your delivery receipt and will contain the
        information you need to access your downloads.
    </p>
    <p style="padding-top: 4pt; text-indent: 0pt; text-align: left"><br /></p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        Downloads from our servers are closely monitored to ensure you are able
        to successfully access our products. While we are flexible and allow you
        to complete
    </p>
    <p
        style="
            padding-top: 3pt;
            padding-left: 5pt;
            text-indent: 0pt;
            text-align: left;
        "
    >
        a reasonable number of downloads we will not tolerate download abuse. We
        reserve the right to terminate your access to our download servers.
    </p>
    <p style="padding-top: 4pt; text-indent: 0pt; text-align: left"><br /></p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        <a href="mailto:info@speKwo.com" class="a" target="_blank"
            >If for any reason after 72 hours you have not received your
            download email, please contact our customer support team immediately
            at </a
        ><a href="mailto:info@speKwo.com" target="_blank"
            >info&#64;speKwo.com</a
        >
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        Refund Policy
    </h2>
    <p
        style="
            padding-top: 15pt;
            padding-left: 5pt;
            text-indent: 0pt;
            text-align: left;
        "
    >
        We stand behind our products and your satisfaction with them is
        important to us. However, because our products are digital goods
        delivered via Internet download we generally offer no refunds.
    </p>
    <p style="padding-top: 5pt; text-indent: 0pt; text-align: left"><br /></p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        Although we use highly sophisticated delivery methods, occasionally your
        email or Internet provider may block, delay or filter your delivery
        email. Under these circumstances we will categorically not offer a
        refund and the product will be deemed to have been delivered under our
        72 hour guarantee. Proof of delivery attempt shall serve as proof of
        product delivery.
    </p>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        Third Party Products and Services
    </h2>
    <p
        style="
            padding-top: 15pt;
            padding-left: 5pt;
            text-indent: 0pt;
            text-align: left;
        "
    >
        Our Service contains links to, or content from, third party websites and
        other products and services. The fact that we link to a website or
        display an advertisement or other third party content is not an
        endorsement, authorization or representation of our affiliation with any
        of those third parties, nor is it an endorsement of their privacy or
        information security policies or practices.
    </p>
    <p style="padding-top: 4pt; text-indent: 0pt; text-align: left"><br /></p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        We do not exercise control over third party websites, products or
        services. These other products and services may place their own cookies
        or other files on your computer or other device, collect data or solicit
        personal information from you.
    </p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        Other persons and entities follow different rules regarding the use or
        disclosure of the information you submit to them or they collect from
        you. We encourage you to learn about the privacy practices of those
        third parties.
    </p>
    <p style="padding-top: 4pt; text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        Our Policy Toward Children
    </h2>
    <p
        style="
            padding-top: 15pt;
            padding-left: 5pt;
            text-indent: 0pt;
            text-align: left;
        "
    >
        We do not knowingly collect personally identifiable information from
        children under 13 via our Service, and do not target our Service to
        children under 13. We encourage
    </p>
    <p
        style="
            padding-top: 3pt;
            padding-left: 5pt;
            text-indent: 0pt;
            text-align: left;
        "
    >
        parents and guardians to take an active role in their children’s online
        activities and interests.
    </p>
    <p style="padding-top: 4pt; text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        Changes to this Privacy Policy
    </h2>
    <p
        style="
            padding-top: 15pt;
            padding-left: 5pt;
            text-indent: 0pt;
            text-align: justify;
        "
    >
        We will occasionally update this Privacy Policy. If we make changes, we
        will revise this Privacy Policy to reflect such changes and revise the
        effective date included at the end of this policy.
    </p>
    <p style="padding-top: 5pt; text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        Contacting Us
    </h2>
    <p
        style="
            padding-top: 15pt;
            padding-left: 5pt;
            text-indent: 0pt;
            text-align: left;
        "
    >
        <a href="mailto:info@SpeKwo.com" class="a" target="_blank"
            >If you have any questions about this Privacy Policy, please feel
            free to contact SpeKwo.com directly at </a
        ><a href="mailto:info@SpeKwo.com" target="_blank"
            >info&#64;SpeKwo.com.</a
        >
    </p>
    <p style="padding-top: 5pt; text-indent: 0pt; text-align: left"><br /></p>
    <h2 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        Terms Of Service Agreement
    </h2>
    <p
        style="
            padding-top: 15pt;
            padding-left: 5pt;
            text-indent: 0pt;
            text-align: left;
        "
    >
        IMPORTANT NOTICE TO USER:
    </p>
    <p style="padding-top: 5pt; text-indent: 0pt; text-align: left"><br /></p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        PLEASE READ THIS DOCUMENT CAREFULLY. This Terms of Service (“Agreement”)
        is a legal agreement between you (referred to herein as “you” or “your”)
    </p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        and SpeKwo, LLC (“SpeKwo.com”, “we”, “our”, or “us”) for access to and
        use of our website available at (the “Website”) and the related
        software, feeds, and online services owned, controlled or offered by us
        (referred to collectively as the “Service”). BY ACCESSING OR USING THE
        SERVICE, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS
        AGREEMENT.
    </p>
    <p style="padding-top: 5pt; text-indent: 0pt; text-align: left"><br /></p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        IN ADDITION TO THIS AGREEMENT, YOU ACKNOWLEDGE THAT USE OF THE
        SpeKwo.com WEBSITE (each, an “App”) IS GOVERNED BY AN APPLICABLE END
        USER LICENSE AGREEMENT (each, a “EULA”). YOU MAY ONLY USE THE WEBSITE IF
        YOU AGREE TO THE APPLICABLE EULA.
    </p>
    <p style="padding-top: 5pt; text-indent: 0pt; text-align: left"><br /></p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        IF ANY OF THE TERMS OF THIS AGREEMENT ARE UNACCEPTABLE TO YOU OR IN THE
        EVENT THAT ANY FUTURE CHANGES ARE UNACCEPTABLE TO YOU, DO NOT USE THE
        SERVICE (INCLUDING THE APP). YOUR CONTINUED USE OF THE SERVICE NOW, OR
        FOLLOWING THE POSTING OF ANY CHANGES IN THIS AGREEMENT, WILL INDICATE
        ACCEPTANCE AND AGREEMENT BY YOU OF SUCH CHANGES.
    </p>
    <p style="padding-top: 5pt; text-indent: 0pt; text-align: left"><br /></p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO ENTER INTO THIS
        AGREEMENT AND TO BE BOUND BY ITS TERMS. IF YOU ARE ACTING ON BEHALF
    </p>
    <p
        style="
            padding-top: 3pt;
            padding-left: 5pt;
            text-indent: 0pt;
            text-align: left;
        "
    >
        OF A COMPANY OR ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO
        BIND SUCH ENTITY.
    </p>
    <p style="padding-top: 4pt; text-indent: 0pt; text-align: left"><br /></p>
    <ol id="l7">
        <li data-list-text="1.">
            <p
                class="s4"
                style="padding-left: 17pt; text-indent: -11pt; text-align: left"
            >
                The Service.
            </p>
            <ol id="l8">
                <li data-list-text="A.">
                    <p
                        style="
                            padding-top: 15pt;
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        You agree that (i) you will use the Service solely in
                        accordance with this Agreement, (ii) all information
                        supplied by you to us will be true, accurate, current
                        and complete, (iii) you may receive electronic
                        communications (e.g., e-mail notices) from us from time
                        to time, and (iv) you will review the latest version of
                        this Agreement posted on the Service from time to time
                        to check for amendments that may apply to you (as more
                        fully described in Section 12 below). We retain the
                        right at our sole discretion to deny or suspend access
                        to the Service to anyone, at any time and for any
                        reason, without liability.
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="B.">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        You acknowledge and agree that your use of the Service,
                        including, without limitation, the storage of any data,
                        files, information and/or other materials on a server
                        owned or under our control or in any way connected to
                        the Service, shall be at your sole risk and
                        responsibility and we shall have no obligation to
                        back-up such data, files, information and/or other
                        materials. We expressly reserve the right to limit
                        storage capacity and to remove and/or delete any data,
                        files, and/or other information stored or used in
                        connection with the Service for any reason including,
                        without limitation, if we deem, in our sole discretion,
                        such data to be in violation of this Agreement and/or
                        any rule or policy of ours and/or any local, state, or
                        federal law or regulation.
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="C.">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        You are responsible for any breach of your obligations
                        under this Agreement and for the consequences (including
                        any loss or damage which we may suffer) of any such
                        breach.
                    </p>
                    <p
                        style="
                            padding-top: 4pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="D.">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        We cannot always foresee or anticipate technical or
                        other difficulties which may result in failure to obtain
                        data or loss of data, personalization settings, or other
                        interruptions. We cannot assume responsibility for the
                        timeliness, accuracy, deletion, non-delivery or failure
                        to store any user data, communications or settings.
                    </p>
                    <p
                        style="
                            padding-top: 4pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="E.">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        Your mobile carrier’s normal messaging, data and other
                        rates and fees will apply to all communications we send
                        to your mobile device and to your access of the Service
                        via your mobile device. Your carrier may prohibit or
                        restrict certain capabilities of the Service, and
                        certain capabilities of the Service may be incompatible
                        with your carrier or mobile device. You should check
                        with your carrier to find out what plans are available
                        and how much they cost. Wireless service is not
                        available in all areas. Not all handsets are supported.
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
            </ol>
        </li>
        <li data-list-text="2.">
            <p
                class="s4"
                style="padding-left: 18pt; text-indent: -12pt; text-align: left"
            >
                Restrictions on Use of the Service.
            </p>
            <p
                style="
                    padding-top: 3pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: left;
                "
            >
                You represent, warrant, and agree that you will not:
            </p>
            <p style="padding-top: 5pt; text-indent: 0pt; text-align: left">
                <br />
            </p>
            <ol id="l9">
                <li data-list-text="(i)">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        use the Service in any unlawful manner, for any unlawful
                        purpose, or in any manner inconsistent with this
                        Agreement and/or any and all applicable local, state and
                        federal laws and regulations and international treaties;
                    </p>
                    <p
                        style="
                            padding-top: 4pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="(ii)">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        use the Service for the distribution, housing,
                        processing, propagation, storage, or otherwise handling
                        in any way abusive, defamatory, harassing, libelous,
                        lewd, libelous, obscene, pornographic, threatening, or
                        tortuous material, or any false or misleading material,
                        or any other material (including links to such material)
                        that we deem, in our sole discretion, to be
                        objectionable whether or not such material is unlawful;
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="(iii)">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        permit or otherwise enable unauthorized users to access
                        and/or use the password-protected portions of the
                        Service;
                    </p>
                    <p
                        style="
                            padding-top: 4pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="(iv)">
                    <p
                        style="
                            padding-left: 23pt;
                            text-indent: -18pt;
                            text-align: left;
                        "
                    >
                        use the Service to export data in violation of
                        applicable U.S. laws or regulations;
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="(v)">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        sell, copy, duplicate, rent, lease, loan, distribute,
                        transfer, or sublicense the Service, or otherwise permit
                        any third party to use or have access to the Service for
                        any purpose (except as expressly permitted by us in
                        writing) or decompile, reverse engineer, disassemble,
                        modify, create a derivative work of, display in human
                        readable form, attempt to discover any source code, or
                        otherwise use any software that enables or comprises any
                        part of the Service;
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="(vi)">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        remove any copyright, trademark, patent or other
                        proprietary notices from the Service;
                    </p>
                    <p
                        style="
                            padding-top: 4pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="(vii)">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        distribute, publish, exhibit, or otherwise use the
                        Service, in any manner and for any purpose not expressly
                        permitted under this Agreement;
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="(viii)">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        frame or utilize framing techniques to enclose the
                        Service, or any portion thereof;
                    </p>
                    <p
                        style="
                            padding-top: 4pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="(ix)">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        exploit the Service or collect any data incorporated in
                        the Service in any automated manner through the use of
                        bots, metaspiders, crawlers or any other automated
                        means;
                    </p>
                    <p
                        style="
                            padding-top: 4pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="(x)">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        register as a user of the Service by providing false,
                        inaccurate, or misleading information including but not
                        limited to impersonating another by name or email;
                        Specifically to re-sellers and vendors that you will not
                        use this service on behalf of
                    </p>
                    <p
                        style="
                            padding-top: 3pt;
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        your client, or permit any staff or employee to do the
                        same, and warrant that you will instruct clients to
                        upload reports themselves.
                    </p>
                    <p
                        style="
                            padding-top: 4pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="(xi)">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: justify;
                        "
                    >
                        use the Service to impersonate any person or entity,
                        including, but not limited to, an employee of ours, or
                        falsely state or otherwise misrepresent your affiliation
                        with a person or entity;
                    </p>
                    <p
                        style="
                            padding-top: 4pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="(xii)">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        collect personal data about other users of the Service
                        for commercial or any other purposes;
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="(xiii)">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        submit or post irrelevant Content (as defined in Section
                        7 below), repeatedly submit or post the same or similar
                        Content or otherwise impose an unreasonable or
                        disproportionately large load on our infrastructure;
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="(xiv)">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        attempt to gain unauthorized access to our computer
                        systems or engage in any activity that disrupts,
                        diminishes the quality of, interferes with the
                        performance of, or impairs the functionality of, the
                        Service (or the servers and networks which are connected
                        to the Service);
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="(xv)">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        make available Content that constitutes or contains
                        “affiliate marketing,” “link referral code,” “junk
                        mail,” “spam,” “chain letters,” “pyramid schemes,” or
                        unsolicited commercial advertisement, except to the
                        extent such Content is provided to you by us or one of
                        our affiliates for a purpose specifically authorized by
                        us in writing; or
                    </p>
                    <p
                        style="
                            padding-top: 4pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="(xvi)">
                    <p
                        style="
                            padding-left: 29pt;
                            text-indent: -24pt;
                            text-align: left;
                        "
                    >
                        create or attempt to create multiple user accounts on
                        the Service.
                    </p>
                </li>
            </ol>
            <p style="padding-top: 5pt; text-indent: 0pt; text-align: left">
                <br />
            </p>
        </li>
        <li data-list-text="3.">
            <p
                class="s4"
                style="padding-left: 18pt; text-indent: -13pt; text-align: left"
            >
                Provision of the Service by Us.
            </p>
            <p
                style="
                    padding-top: 15pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: left;
                "
            >
                We are constantly improving the Service in order to provide the
                best possible experience for our users. You acknowledge and
                agree that the form and nature of the Service which we provide
                may change from time to time without prior notice to you. Any
                new features that augment or enhance the current Service shall
                be subject to this Agreement.
            </p>
            <p style="padding-top: 5pt; text-indent: 0pt; text-align: left">
                <br />
            </p>
        </li>
        <li data-list-text="4.">
            <p
                class="s4"
                style="padding-left: 18pt; text-indent: -13pt; text-align: left"
            >
                Access to the Service; Reservation of Rights.
            </p>
            <ol id="l10">
                <li data-list-text="A.">
                    <p
                        style="
                            padding-top: 15pt;
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        Provided that you comply at all times with the terms of
                        this Agreement, we hereby give you a personal,
                        worldwide, revocable, non-assignable and non- exclusive
                        right to access and use the Service to the extent we
                        make it available to you in the manner and for the
                        purposes expressly permitted by the Agreement. You may
                        not assign (or grant a sub-license of) your rights to
                        use the Service, grant a security interest in or over
                        your rights to use the Service, or otherwise transfer
                        any
                    </p>
                    <p
                        style="
                            padding-top: 3pt;
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        part of your rights to use the Service and any such
                        attempt shall be null and void at the time of such
                        attempt.
                    </p>
                    <p
                        style="
                            padding-top: 4pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="B.">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        We reserve all right, title and interest in and to the
                        Service not expressly granted to you under this
                        Agreement. There are no implied licenses under this
                        Agreement.
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
            </ol>
        </li>
        <li data-list-text="5.">
            <p
                class="s4"
                style="padding-left: 18pt; text-indent: -13pt; text-align: left"
            >
                Your Password and Account Security.
            </p>
            <p
                style="
                    padding-top: 15pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: left;
                "
            >
                You agree and understand that you are responsible for
                maintaining the confidentiality of passwords associated with any
                account you use to access the Service. Accordingly, you agree
                that you will be responsible to us for all activities that occur
                under your account. If you become aware of any unauthorized use
                of your password or of your account, you agree to notify us
                immediately by using the “Contact Us” feature on the Website.
            </p>
            <p style="padding-top: 5pt; text-indent: 0pt; text-align: left">
                <br />
            </p>
        </li>
        <li data-list-text="6.">
            <p
                class="s4"
                style="padding-left: 19pt; text-indent: -14pt; text-align: left"
            >
                Content in the Service; Citations.
            </p>
            <ol id="l11">
                <li data-list-text="A.">
                    <p
                        style="
                            padding-top: 15pt;
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        You understand that all information (such as data files,
                        location information, written text, computer software,
                        music, audio files or other sounds, photographs, videos
                        or other images) which you may have access to as part
                        of, or through your use of, the Service are the sole
                        responsibility of the person from which such content
                        originated. All such information is referred to as
                        “Content”. Certain Content is made available only to
                        persons that have subscribed to receive access thereto
                        by entering into a separate Subscription Agreement with
                        SpeKwo.com.
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="B.">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        You acknowledge that Content presented to you as part of
                        the Service may be protected by intellectual property
                        rights which are owned by third parties, such as the
                        persons and/or entities that provide that Content to us
                        (or by other persons or companies on their behalf). You
                        may not modify, rent, lease, loan, sell, distribute or
                        create derivative works based on this Content (either in
                        whole or in part) unless you have been specifically told
                        that you may do so by us or by the owners of that
                        Content, in writing.
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="C.">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        With respect to Content comprised solely of SpeKwo.com’s
                        original Content, if you include an Appropriate Citation
                        (as defined below), then you may reproduce no more than
                        one graphic or slide or chart and no more than one
                        paragraph of text (or three non-contiguous sentences) in
                        any one article or report you create (if you are a
                        member of the Press) or any one internal presentation,
                        without our prior written approval. An “Appropriate
                        Citation” is a reasonably prominent citation to Content
                        that is clearly sourced to “SpeKwo, LLC” and includes
                        the date (year) that such Content was originally
                        published and identifies all third party sources of all
                        underlying data used by SpeKwo.com to generate such
                        Content.
                    </p>
                </li>
                <li data-list-text="D.">
                    <p
                        style="
                            padding-top: 3pt;
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        We reserve the right (but shall have no obligation) to
                        pre-screen, review, flag, filter, modify, refuse or
                        remove any or all Content.
                    </p>
                    <p
                        style="
                            padding-top: 4pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="E.">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        You understand that by using the Service you may be
                        exposed to Content that you may find offensive, indecent
                        or objectionable and that, in this respect, you use the
                        Service at your own risk.
                    </p>
                    <p
                        style="
                            padding-top: 4pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="F.">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: justify;
                        "
                    >
                        You agree that you are responsible for (and that we have
                        no responsibility to you or to any third party for) any
                        Content that you create, transmit or display while using
                        the Service and for the consequences of your actions
                        (including any loss or damage which we may suffer) by
                        doing so.
                    </p>
                    <p
                        style="
                            padding-top: 4pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
            </ol>
        </li>
        <li data-list-text="7.">
            <p
                class="s4"
                style="
                    padding-left: 18pt;
                    text-indent: -13pt;
                    text-align: justify;
                "
            >
                Intellectual Property.
            </p>
            <ol id="l12">
                <li data-list-text="A.">
                    <p
                        style="
                            padding-top: 15pt;
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        You acknowledge and agree that we (or our licensors) own
                        all legal right, title and interest in and to the
                        Service, including any intellectual property rights
                        which subsist in the Service (whether those rights
                        happen to be registered or not, and wherever in the
                        world those rights may exist) including, without
                        limitation, all rights with respect to copyrights,
                        patents, trademarks, service marks, moral rights, trade
                        names, domain names, technology, mask works, know-how,
                        design rights, trade dress, trade secrets, inventions,
                        ideas, processes, formulas, source code and object code,
                        data, and similar rights including the information in
                        any application, registration, or renewal thereof that
                        may be protected under the intellectual property laws,
                        regulations, or rules of any country. Without limiting
                        the foregoing, all data, graphics, icons, images, logos,
                        text, and the compilation of all content therein, and
                        all service marks, trademarks, trade names, and trade
                        dress depicted on the Service are owned by us (or our
                        licensors).
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="B.">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        You agree that you shall not remove, obscure, or alter
                        any proprietary rights notices (including copyright and
                        trademark notices) which may be affixed to Content or
                        contained within the Service.
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="C.">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        You agree that in using the Service, you will not use
                        any trademark, service mark, trade name, logo of any
                        company or organization in a way that is likely or
                        intended to cause confusion about the owner or
                        authorized user of such marks, names or logos.
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
            </ol>
        </li>
        <li data-list-text="8.">
            <p
                class="s4"
                style="
                    padding-left: 19pt;
                    text-indent: -13pt;
                    text-align: justify;
                "
            >
                Rights you grant to us.
            </p>
            <ol id="l13">
                <li data-list-text="A.">
                    <p
                        style="
                            padding-top: 15pt;
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        By submitting, posting or displaying Content on or
                        through the Service, you give us a worldwide, perpetual,
                        fully-sublicensable, irrevocable, royalty-free, and non-
                        exclusive license to reproduce, adapt, modify,
                        translate, publish, publicly perform,
                    </p>
                    <p
                        style="
                            padding-top: 3pt;
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        publicly display, distribute and otherwise exploit such
                        Content in any manner or media whatsoever, now known or
                        hereafter developed.
                    </p>
                    <p
                        style="
                            padding-top: 4pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="B.">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        You agree that we may use your feedback, suggestions, or
                        ideas in any way, including in future modifications of
                        the Service, other products or services, advertising or
                        marketing materials. You grant us a perpetual,
                        worldwide, fully transferable, sublicensable,
                        irrevocable, fully-paid up, royalty free license to use
                        the feedback you provide to us in any way.
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="C.">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        You agree that we are not responsible for protecting and
                        enforcing any intellectual property rights granted by
                        you to us in connection with this Agreement and that we
                        have no obligation to do so on your behalf.
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="D.">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        In connection with Content you submit, post or display
                        via the Service, you affirm, represent, and warrant
                        that: you own or have the necessary licenses, rights,
                        consents, and permissions to use and authorize us to use
                        such Content in the manner contemplated by the Service
                        and this Agreement. You further agree that you will not
                        submit material that is copyrighted, protected by trade
                        secret or otherwise subject to third party proprietary
                        rights, including privacy and publicity rights, unless
                        you are the owner of such rights or have written
                        permission from their rightful owner to post the
                        material and to grant us all of the license rights
                        granted herein.
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
                <li data-list-text="E.">
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        You understand that we may (a) transmit or distribute
                        your Content over various public networks and in various
                        media; and (b) make such changes to your Content as we
                        deem necessary to conform and adapt that Content to the
                        technical requirements of connecting networks, devices,
                        services or media. You agree that this license shall
                        permit us to take these actions.
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                    <p
                        style="
                            padding-left: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        G. You represent and warrant to us that you have all the
                        rights, capacity, power and authority necessary to grant
                        the above licenses.
                    </p>
                    <p
                        style="
                            padding-top: 5pt;
                            text-indent: 0pt;
                            text-align: left;
                        "
                    >
                        <br />
                    </p>
                </li>
            </ol>
        </li>
        <li data-list-text="9.">
            <p
                class="s4"
                style="padding-left: 19pt; text-indent: -13pt; text-align: left"
            >
                Copyright Agent.
            </p>
            <p
                style="
                    padding-top: 15pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: left;
                "
            >
                The Digital Millennium Copyright Act of 1998 (the “DMCA”)
                provides recourse for copyright owners who believe that material
                appearing on the Internet infringes their rights under U.S.
                copyright law. If you believe that your work has been copied and
                posted on the Service in a way that constitutes copyright
                infringement, please provide us with the following information:
                an electronic or physical signature of the copyright owner or of
                the person authorized to act on behalf of the owner of the
                copyright interest; a description of the copyrighted work that
                you claim has been infringed; a description of where the
                material that you claim is infringing is located on the Service;
                your address, telephone number, and e-mail address; a written
            </p>
            <p
                style="
                    padding-top: 3pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: left;
                "
            >
                statement by you that you have a good faith belief that the
                disputed use is not authorized by the copyright owner, its
                agent, or the law; a statement by you, made under penalty of
                perjury, that the above information in your notice is accurate
                and that you are the copyright owner or are authorized to act on
                the copyright owner’s behalf. Any notification by a copyright
                owner or a person authorized to act on such copyright owner’s
                behalf that fails to comply with requirements of the DMCA shall
                not be considered sufficient notice and shall not be deemed to
                confer upon us actual knowledge of facts or circumstances from
                which infringing material or acts are evident.
            </p>
            <p style="padding-top: 5pt; text-indent: 0pt; text-align: left">
                <br />
            </p>
            <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
                You should note that there can be penalties for false claims
                under the DMCA.
            </p>
            <p style="padding-top: 5pt; text-indent: 0pt; text-align: left">
                <br />
            </p>
        </li>
        <li data-list-text="10.">
            <p
                class="s4"
                style="padding-left: 25pt; text-indent: -19pt; text-align: left"
            >
                Changes to Agreement.
            </p>
            <p
                style="
                    padding-top: 15pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: left;
                "
            >
                We reserve the right to change, amend and/or modify this
                Agreement, in whole or in part, at any time, and when we do so,
                we will provide you with reasonable notice that a change,
                amendment or modification has been made. You agree that
                reasonable notice includes, by way of example, a reasonably
                prominent posting on the Website or the sending of an e-mail to
                you, indicating that this Agreement has been changed. You hereby
                acknowledge and agree that such changes, amendments and/or
                modifications will become effective when posted on the Service
                or as otherwise provided by us. From time to time, we may post
                on the Service or otherwise notify you of additional or
                different rules and policies relating to the Service. These
                rules and policies shall thereafter be part of this Agreement.
                If you use the Service after the posting or other notice of
                changes in this Agreement or changed rules or policies, you are
                agreeing to follow and be bound by them for such use.
            </p>
            <p style="padding-top: 5pt; text-indent: 0pt; text-align: left">
                <br />
            </p>
        </li>
        <li data-list-text="11.">
            <p
                class="s4"
                style="padding-left: 23pt; text-indent: -17pt; text-align: left"
            >
                NO WARRANTIES.
            </p>
            <p
                style="
                    padding-top: 15pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: left;
                "
            >
                THE SERVICE OF ESTIMATING COST OF REPAIRS OF INSPECTION REPORTS
                IS PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS,
                WITHOUT WARRANTY OR REPRESENTATION OF ANY KIND. OUR PRICING
                REPORTS ARE CATEGORICALLY NOT A “BID FOR REPAIRS” AND SHOULD NOT
                BE INTERPRETED AS SUCH, AND MAKE NO COMMITMENT OR OBLIGATION TO
                COMPLETE ANY REPAIRS LISTED IN OUR REPORTS. IF WE CHOOSE TO
                COMPLETE REPAIRS AT YOUR REQUEST WE WILL REVIEW ALL ITEMS
                NEEDING REPAIRED AND CONFIRM OUR QUOTE UPON ARRIVAL.TO THE
                FULLEST EXTENT PERMITTED BY LAW, WE EXPRESSLY DISCLAIM ALL
                WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE,
                INCLUDING WITHOUT LIMITATION, ANY WARRANTY OF MERCHANTABILITY,
                TITLE, NON-INFRINGEMENT, QUALITY, AND/OR FITNESS FOR A
                PARTICULAR PURPOSE. WITHOUT LIMITING THE FOREGOING, WE DO NOT
                WARRANT THE ACCURACY, RELIABILITY OR COMPLETENESS OF ANY
                INFORMATION PROVIDED BY US IN CONNECTION WITH
            </p>
            <p
                style="
                    padding-top: 3pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: left;
                "
            >
                YOUR USE OF THE SERVICE, OR THAT THE SERVICE, INCLUDING, WITHOUT
                LIMITATION, ANY DATA, FILES, AND/OR OTHER INFORMATION STORED ON
                A SERVER OWNED OR UNDER OUR CONTROL OR IN ANY WAY CONNECTED WITH
                THE SERVICE, WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE,
                UNINTERRUPTED, ERROR-FREE, VIRUS-FREE OR SECURE. SOME
                JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN DISCLAIMERS
                OR LIMITATIONS OF WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT
                APPLY TO YOU. ANY MATERIAL OR INFORMATION DOWNLOADED OR
                OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DONE AT
                YOUR OWN DISCRETION AND RISK. YOU WILL BE SOLELY RESPONSIBLE FOR
                ANY LOSS OR DAMAGE TO YOUR COMPUTER SYSTEM OR OTHER DEVICE OR
                LOSS OF DATA OR PRIVACY THAT RESULTS FROM THE DOWNLOAD OF ANY
                SUCH MATERIAL OR INFORMATION. NO ADVICE OR INFORMATION, WHETHER
                ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR THROUGH OR FROM THE
                SERVICE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE
                AGREEMENT. WE DO PROVIDE A TWELVE (12) MONTH LABOR WARRANTY OF
                ALL REPAIRS COMPLETED BY SPEKWO EMPLOYEES OR SUBCONTRACTORS.
            </p>
            <p style="padding-top: 5pt; text-indent: 0pt; text-align: left">
                <br />
            </p>
        </li>
        <li data-list-text="12.">
            <p
                class="s4"
                style="padding-left: 24pt; text-indent: -18pt; text-align: left"
            >
                DISCLAIMER OF LIABILITY.
            </p>
            <p
                style="
                    padding-top: 15pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: left;
                "
            >
                TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL WE,
                OUR AFFILIATES, DIRECTORS, MEMBERS, MANAGERS, OFFICERS,
                EMPLOYEES, AGENTS OR THIRD PARTY LICENSORS, BE LIABLE TO YOU OR
                ANY THIRD PARTY FOR ANY SPECIAL, INDIRECT, INCIDENTAL,
                CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES OR LOSSES ARISING
                OUT OF OR RELATING TO THIS AGREEMENT AND/OR THE SERVICE PROVIDED
                HEREUNDER, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGES. THIS LIMITATION OF LIABILITY APPLIES WHETHER THE
                ALLEGED LIABILITY IS BASED ON CONTRACT, NEGLIGENCE,
                RECKLESSNESS, PROFESSIONAL NEGLIGENCE, TORT, STRICT LIABILITY OR
                ANY OTHER BASIS OR LEGAL THEORY. SUCH LIMITATION OF LIABILITY
                SHALL APPLY WHETHER OR NOT THE DAMAGES ARISE DIRECTLY OR
                INDIRECTLY FROM: (i) THE USE OR MISUSE OF, OR RELIANCE UPON, THE
                SERVICE PROVIDED HEREUNDER;
            </p>
            <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
                (ii) THE INABILITY TO USE THE SERVICE FOR ANY REASON, INCLUDING,
                WITHOUT LIMITATION, FROM MISTAKES, OMISSIONS, INTERRUPTIONS,
                DELETION OF FILES, ERRORS, DEFECTS, DELAYS IN OPERATION, OR ANY
                FAILURE OF PERFORMANCE NOT LIMITED TO ACTS OF GOD, COMMUNICATION
                FAILURE, THEFT, OR DESTRUCTION; (iii) THE INTERRUPTION,
                SUSPENSION, OR TERMINATION OF THE SERVICE; OR (iv) THE DELETION
                AND/OR CORRUPTION OF ANY DATA, INFORMATION, DOCUMENTS, FILES
                AND/OR ANY OTHER MATERIALS STORED ON A SERVER OWNED OR UNDER OUR
                CONTROL OR IN ANY WAY CONNECTED TO THE SERVICE. SUCH LIMITATION
                ON LIABILITY SHALL APPLY NOTWITHSTANDING ANY FAILURE OF
                ESSENTIAL PURPOSE OF ANY LIMITED REMEDY AND TO THE FULLEST
                EXTENT PERMITTED BY LAW.
            </p>
        </li>
        <li data-list-text="13.">
            <p
                class="s4"
                style="
                    padding-top: 3pt;
                    padding-left: 24pt;
                    text-indent: -18pt;
                    text-align: left;
                "
            >
                Release and Indemnification.
            </p>
            <p
                style="
                    padding-top: 15pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: left;
                "
            >
                You agree to release, indemnify and hold harmless us, our
                affiliates, directors, members, officers, employees, agents and
                third party licensors, from and against any and all liabilities,
                losses, damages, claims and expenses, including attorneys’ fees,
                with respect to (i) your use or misuse of, or reliance upon, the
                Service or any Content, (ii) your violation of this Agreement or
                rights of another, and/or (iii) any Content you make available
                through the Service. For the avoidance of doubt, this section
                shall survive the termination of this Agreement.
            </p>
            <p style="padding-top: 5pt; text-indent: 0pt; text-align: left">
                <br />
            </p>
        </li>
        <li data-list-text="14.">
            <p
                class="s4"
                style="padding-left: 24pt; text-indent: -19pt; text-align: left"
            >
                California Waiver.
            </p>
            <p
                style="
                    padding-left: 5pt;
                    text-indent: 0pt;
                    line-height: 33pt;
                    text-align: left;
                "
            >
                If you are a California resident, you waive California Civil
                Code 1542, which says: “A general release does not extend to
                claims which the creditor does not know or
            </p>
            <p
                style="
                    padding-left: 5pt;
                    text-indent: 0pt;
                    line-height: 10pt;
                    text-align: left;
                "
            >
                suspect to exist in his favor at the time of executing the
                release, which if known by
            </p>
            <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
                him must have materially affected his settlement with the
                debtor.”
            </p>
            <p style="padding-top: 5pt; text-indent: 0pt; text-align: left">
                <br />
            </p>
        </li>
        <li data-list-text="15.">
            <p
                class="s4"
                style="padding-left: 24pt; text-indent: -19pt; text-align: left"
            >
                Term and Termination.
            </p>
            <p
                style="
                    padding-top: 15pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: justify;
                "
            >
                This Agreement is effective until terminated by us or you. We
                shall have the right to terminate this Agreement including,
                without limitation, your right to access and use the Service, at
                any time in our sole discretion and without advance notice to
                you.
            </p>
            <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
                The licenses granted herein by us shall automatically terminate
                without advance notice if you fail to comply with any material
                provision of this Agreement. You may terminate this Agreement at
                any time by deleting all copies of the App in your possession or
                control, if any, and discontinuing use of any and all parts of
                the Service. Upon termination of this Agreement for any reason,
                you shall immediately cease using the Service. Removal of the
                App or discontinuing use of the Service does not relieve you of
                your prior liabilities and continuing obligations under this
                Agreement, as applicable.
            </p>
            <p style="padding-top: 5pt; text-indent: 0pt; text-align: left">
                <br />
            </p>
        </li>
        <li data-list-text="16.">
            <p
                class="s4"
                style="padding-left: 25pt; text-indent: -19pt; text-align: left"
            >
                Governing Law and Venue for Disputes.
            </p>
        </li>
    </ol>
    <p
        style="
            padding-top: 15pt;
            padding-left: 5pt;
            text-indent: 0pt;
            text-align: left;
        "
    >
        This Agreement, and your relationship with us under this Agreement,
        shall be governed by the laws of the State of North Carolina without
        regard to its conflict or choice of laws provisions. Any dispute with
        us, or our officers, directors, employees, agents or affiliates, arising
        under or in relation to this Agreement shall be resolved exclusively
        through the federal or state courts located in Lincoln County, North
        Carolina (and courts with appellate jurisdiction therefrom), except with
        respect to imminent harm requiring temporary or preliminary injunctive
        relief in which case we may seek such relief in any court with
        jurisdiction over the parties. You understand that, in return for
        agreement to this provision and the disclaimer of liability and
    </p>
    <p
        style="
            padding-top: 3pt;
            padding-left: 5pt;
            text-indent: 0pt;
            text-align: left;
        "
    >
        disclaimer of warranties herein, we are able to offer the Service at the
        terms designated, and that your assent to this provision is an
        indispensable consideration to this Agreement. 18. Severability.
    </p>
    <p style="padding-top: 5pt; text-indent: 0pt; text-align: left"><br /></p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
        If any provision in this Agreement is invalid or unenforceable or
        contrary to applicable law, such provision shall be construed, limited,
        or altered, as necessary, to eliminate the invalidity or
        unenforceability or the conflict with applicable law, and all other
        provisions of this Agreement shall remain in effect.
    </p>
    <p style="padding-top: 5pt; text-indent: 0pt; text-align: left"><br /></p>
    <ol id="l14">
        <li data-list-text="19.">
            <p
                class="s4"
                style="padding-left: 25pt; text-indent: -19pt; text-align: left"
            >
                No Assignment, Sublicense or Transfer.
            </p>
            <p
                style="
                    padding-top: 15pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: left;
                "
            >
                You may not assign, sublicense, or transfer this Agreement or
                any rights or obligations hereunder without our prior written
                consent. Any such attempted assignment, sublicense, or transfer
                will be null and void and we, in our sole discretion, shall have
                the right to immediately terminate this Agreement.
            </p>
            <p style="padding-top: 5pt; text-indent: 0pt; text-align: left">
                <br />
            </p>
        </li>
        <li data-list-text="20.">
            <p
                class="s4"
                style="padding-left: 26pt; text-indent: -21pt; text-align: left"
            >
                Communications by us.
            </p>
            <p
                style="
                    padding-top: 15pt;
                    padding-left: 5pt;
                    text-indent: 0pt;
                    text-align: left;
                "
            >
                You agree that we may communicate with you electronically. Such
                electronic communications may consist of e-mail, notices posted
                on the Service, and other communications. You agree that all
                agreements, notices, disclosures, and other communications we
                send to you electronically will satisfy any requirement that
                such communication be in writing and, to the extent intended,
                such communication will be an enforceable and binding term or
                amendment to this Agreement.
            </p>
            <p style="padding-top: 5pt; text-indent: 0pt; text-align: left">
                <br />
            </p>
        </li>
        <li data-list-text="21.">
            <p
                class="s4"
                style="padding-left: 24pt; text-indent: -18pt; text-align: left"
            >
                Entire Agreement.
            </p>
        </li>
    </ol>
    <p
        style="
            padding-top: 15pt;
            padding-left: 5pt;
            text-indent: 0pt;
            text-align: left;
        "
    >
        This Agreement sets forth the entire understanding and agreement between
        the parties relating to its subject matter, except with respect to
        subscribers that have subscribed for access to certain Content on the
        Service, for whom a Subscription Agreement shall also apply. All
        provisions that should by their nature survive the termination of this
        Agreement shall survive the expiration of this Agreement. Any waiver of
        or promise not to enforce any right under this Agreement shall not be
        enforceable unless evidenced by a writing signed by the party making
        said waiver or promise. NO VENDOR, DISTRIBUTOR, DEALER, RETAILER, AGENT,
        SALES PERSON, OR OTHER PERSON IS AUTHORIZED BY US TO MODIFY THIS
        AGREEMENT OR TO MAKE ANY WARRANTY, REPRESENTATION, OR PROMISE THAT IS
        DIFFERENT THAN OR IN ADDITION TO THE WARRANTIES, REPRESENTATIONS, OR
        PROMISES EXPRESSLY SET FORTH IN THIS AGREEMENT.
    </p>
</div>
