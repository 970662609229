<div class="about-area sticky-news">
    <!-- <div class="container"> -->
    <spekwo-ticker></spekwo-ticker>
    <!-- </div> -->
</div>

<!-- Footer Area -->
<footer class="footer-area foot-pad">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-6">
                <div class="footer-widget">
                    <!-- <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logos/speKwo_logo.png" alt="Images"></a>
                    </div> -->

                    <div>
                        <video width="576" height="300" controls>
                            <source
                                src="assets/img/logos/speko_home_ins_video.mp4"
                                type="video/mp4"
                            />
                            <source src="movie.ogg" type="video/ogg" />
                            Your browser does not support the video tag.
                        </video>
                        <!-- <p>speKwo wan in the making for over 20 years. The leadership team invested the better part of
                            that
                            time buying, fixing, and selling houses; doing remodels for realtors and investors; and most
                            importantly through those two activities had the opportunity to work through over 2,000
                            inspection quote.
                        </p> -->
                    </div>

                    <p>Copyright &copy; speKwo</p>

                    <!-- <div class="newsletter-area">
                        <form class="newsletter-form" data-toggle="validator" method="POST">
                            <input type="email" class="form-control" placeholder="Your Email*" name="EMAIL">
                            <button class="default-btn" type="submit">Subscribe </button>
                        </form>
                    </div> -->
                </div>
            </div>

            <!-- <div class="col-lg-2 col-md-6">
                <div class="footer-widget">
                    <h3>Useful Links</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/services-1">Services</a></li>
                        <li><a routerLink="/projects">Projects</a></li>
                        <li><a routerLink="/team">Team</a></li>
                        <li><a routerLink="/blog-1">Blog</a></li>
                    </ul>
                </div>
            </div> -->

            <!-- <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>Other Resources</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/services-1">Car Service</a></li>
                        <li><a routerLink="/services-2">Services</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/service-details">Car Details</a></li>
                        <li><a routerLink="/contact">Support</a></li>
                    </ul>
                </div>
            </div> -->

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-2">
                    <div class="footer-widget">
                        <div class="footer-logo">
                            <a routerLink="/"
                                ><img
                                    src="assets/img/logos/speKwo_logo.png"
                                    alt="Images"
                            /></a>
                        </div>

                        <!-- <h3>Address</h3> -->

                        <p class="zero-pad-mar address">
                            Cornelius, North Carolina
                        </p>

                        <p class="zero-pad-mar phone">
                            <span class="phone-number"> 866.646.0200 </span>
                            <a class="email" href="mailto:info@spekwo.com"
                                >info&#64;spekwo.com</a
                            >
                        </p>

                        <p class="zero-pad-mar social-network">
                            <a
                                class="facebook"
                                href="https://www.facebook.com/SpeKwo-344051736111918/"
                                target="_blank"
                                ><i class="bx bxl-facebook icon"></i
                            ></a>
                            <a
                                class="instagram"
                                target="_blank"
                                href="https://instagram.com/spekwo.us?igshid=NjIwNzIyMDk2Mg=="
                                ><i class="bx bxl-instagram icon"></i
                            ></a>
                        </p>
                        <p class="zero-pad-mar address">
                            Visit our
                            <a
                                href="/privacy-policy" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}"
                                >Privacy Policy</a
                            >
                            and
                            <a
                                href="/terms-conditions" routerLinkActive="active" 
                                [routerLinkActiveOptions]="{exact: true}"
                                >Terms and Conditions</a
                            >.
                        </p>

                        <!-- <ul class="footer-list-two">
                            <li>
                                <i class='bx bx-time'></i>
                                Mon - Fri: 8.00am - 6.00pm
                            </li>
                            <li>
                                <i class='bx bx-home-smile'></i>
                                <a href="#" target="_blank">Cornelius, North Carolina</a>
                            </li>
                            <li>
                                <i class='bx bx-phone'></i>
                                <a href="tel: 866.646.0200"> 866.646.0200</a>
                            </li>
                            <li>
                                <i class='bx bxs-envelope'></i>
                                <a href="mailto:info@spekwo.com">info@spekwo.com</a>
                            </li>
                            <li>
                                <i class='bx bxl-facebook'></i>
                                <a href="https://www.facebook.com/SpeKwo-344051736111918/" target="_blank">facebook</a>
                            </li>
                            <li>
                                <i class='bx bxl-instagram'></i>
                                <a href="#">instagram</a>
                            </li>
                            <li>
                                <a href="https://dev.spekwo.com/contractors-join-spekwo/">Contractors join speKwo</a>
                            </li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- Footer Area End -->

<!-- Copyright Area -->
<!-- <div class="copy-right-area">
    <div class="container">
        <div class="copy-right-text text-center">
            {{ formValues.footerSpekwo }}
            <p>{{ formValues.footerSpekwo }} | {{ formValues.footerRightsReserved }} | {{ formValues.footerPoweredBy }}
                <a href="https://www.neosme.com/" target="_blank">{{ formValues.footerNeosme }}</a></p>
        </div>
    </div>
</div> -->
<!-- Copyright Area End -->
