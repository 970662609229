<!-- Start Appointment Area -->
<div *ngIf="!isSubmitted" class="appointment-form main-form">

    <div class="section-title">
        <h2>{{"You are requesting " + currentProduct.productName}}</h2>
    </div>
    <div class="section-title">
        <h3>Billing details</h3>
        <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
        <p>{{progress}}% completed</p>
    </div>

    <ng-container [ngSwitch]="stepperOrientation | async">
        <div *ngSwitchCase="'horizontal'"></div>
        <div *ngSwitchCase="'vertical'"></div>
    </ng-container>

    <mat-horizontal-stepper [linear]="true" class="billing-stepper" [orientation]="(stepperOrientation | async)!"
        (selectionChange)="onStepChange($event)">
        <mat-step [stepControl]="addressForm" label={{productContent.billingForm.formStepper.address}}>
            <form [formGroup]="addressForm">
                <mat-form-field appearance="outline" class="col-lg-12 col-md-12">
                    <mat-label>{{productContent.billingForm.formFields.address}}</mat-label>
                    <input ngx-google-places-autocomplete matInput [options]='options' #placesRef="ngx-places"
                        (onAddressChange)="handleAddressChange($event)"
                        placeholder={{productContent.billingForm.formStepper.address}} formControlName="propertyAddress"
                        required>
                    <mat-error>{{productContent.billingForm.error.addressRequired}}</mat-error>
                </mat-form-field>
                <div>
                    <button mat-button mat-raised-button color="primary" class="mr-4 order-default-btn" matStepperNext
                        [disabled]="!addressForm.valid">Next</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="yourRoleForm" label={{productContent.billingForm.formStepper.whatYourRole}}>
            <form [formGroup]="yourRoleForm">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-sm-3" *ngFor="let role of productContent.yourRole; let i = index;"
                            [value]="role.value" (click)="selectYourRole(role.value, i)">
                            <div class="form-group card" [ngClass]="{'highlight': selectedRoleIndex === i}">
                                <p class="role">{{role.key}}</p>
                                <input type="hidden" name="{{role.key}}" id="{{role.key}}" formControlName="yourRole">
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <button mat-button mat-raised-button color="primary" class="mr-4 order-default-btn"
                        matStepperPrevious>Back</button>
                    <button mat-button mat-raised-button color="primary" class="mr-4 order-default-btn" matStepperNext
                        [disabled]="!yourRoleForm.valid">Next</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="yourInfoForm" label={{productContent.billingForm.formStepper.yourInfo}}>
            <form [formGroup]="yourInfoForm">
                <div class="container">
                    <div class="row">
                        <div class="spinner-border text-primary col-lg-3 col-sm-3 form-loader" role="status"
                            *ngIf="isLoadingList">
                            <span class="sr-only">Loading...</span>
                        </div>

                        <mat-form-field appearance="outline" class="col-lg-3 col-sm-3">
                            <mat-label>{{productContent.billingForm.formFields.yourRole}}</mat-label>
                            <input matInput formControlName="yourRole" readonly required>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="col-lg-3 col-sm-3">
                            <mat-label>{{productContent.billingForm.formFields.yourName}}</mat-label>
                            <input matInput formControlName="yourName" required>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="col-lg-3 col-sm-3">
                            <mat-label>{{productContent.billingForm.formFields.yourCell}}</mat-label>
                            <input matInput formControlName="yourCellNumber" required mask="(000)000-0000">
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="col-lg-3 col-sm-3">
                            <mat-label>{{productContent.billingForm.formFields.yourEmail}}</mat-label>
                            <input matInput formControlName="yourEmail" required [email]="true">
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="col-lg-3 col-sm-3">
                            <mat-label>{{productContent.billingForm.formFields.clientName}}</mat-label>
                            <input matInput formControlName="clientName" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="form-control" class="col-lg-3 col-sm-3">
                            <mat-label>{{productContent.billingForm.formFields.buyerSeller}}</mat-label>
                            <mat-select formControlName="indRole">
                                <mat-option *ngFor="let role of productContent.billingForm.indRoles"
                                    [value]="role.value">
                                    {{role.key}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-lg-3 col-sm-3">
                            <mat-label>{{productContent.billingForm.formFields.clientCell}}</mat-label>
                            <input matInput formControlName="clientCellNumber" required mask="(000)000-0000">
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-lg-3 col-sm-3">
                            <mat-label>{{productContent.billingForm.formFields.clientEmail}}</mat-label>
                            <input matInput formControlName="clientEmail" required [email]="true">
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="col-lg-3 col-sm-3">
                            <mat-label>{{productContent.billingForm.formFields.localMarket}}</mat-label>
                            <mat-select formControlName="marketId"
                                (selectionChange)="onSelect($event,'localMarketName')">
                                <mat-option *ngFor="let market of marketsDropDownMenu" [value]="market.marketId">
                                    {{market.marketName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="col-lg-3 col-sm-3">
                            <mat-label>{{productContent.billingForm.formFields.firmName}}</mat-label>
                            <mat-select formControlName="firmId" (selectionChange)="onSelect($event,'firmName')">
                                <mat-option *ngFor="let firm of firmsDropDownMenu" [value]="firm.firmId">
                                    {{firm.firmsName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="col-lg-3 col-sm-3">
                            <mat-label>{{productContent.billingForm.formFields.affiliateName}}</mat-label>
                            <mat-select formControlName="officeId" (selectionChange)="onSelect($event,'affiliateName')">
                                <mat-option *ngFor="let office of officesDropDownMenu" [value]="office.officeId">
                                    {{office.officesName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div>
                        <span><b>{{productContent.billingForm.formFields.whoQuote}}</b></span>
                    </div>

                    
                    <div class="container">
                        <div class="row">
                    
                          <div class="col-lg-7 col-sm-12">
                            <div class="row mb-3">
                        
                              <div class="col-lg-12 mb-3">
                                <div class="row align-items-center">
                                  <div class="col-md-3">
                                    <mat-checkbox formControlName="meChecked" (change)="quoteEmailChanges($event,'meChecked')">
                                      {{productContent.billingForm.formFields.me}}
                                    </mat-checkbox>
                                  </div>
                                  <div class="col-md-6">
                                    <mat-form-field appearance="outline" class="w-100">
                                      <input matInput formControlName="yourEmail" readonly
                                            [value]="yourInfoForm.value.meChecked ? yourInfoForm.value.yourEmail : null">
                                    </mat-form-field>
                                  </div>
                                </div>
                              </div>
                      
                              <div class="col-lg-12 mb-3">
                                <div class="row align-items-center">
                                  <div class="col-md-3">
                                    <mat-checkbox formControlName="roleChecked" (change)="quoteEmailChanges($event,'roleChecked')">
                                      {{productContent.billingForm.formFields.buyerSellerQuote}}
                                    </mat-checkbox>
                                  </div>
                                  <div class="col-md-6">
                                    <mat-form-field appearance="outline" class="w-100">
                                      <input matInput formControlName="clientEmail" readonly
                                             [value]="yourInfoForm.value.roleChecked ? yourInfoForm.value.clientEmail : null">
                                    </mat-form-field>
                                  </div>
                                </div>
                              </div>
                      
                              <div class="col-lg-12 mb-3">
                                <div class="row align-items-center">
                                  <div class="col-md-3">
                                    <mat-checkbox formControlName="otherChecked" (change)="quoteEmailChanges($event,'otherChecked')">
                                      {{productContent.billingForm.formFields.others}}
                                    </mat-checkbox>
                                  </div>
                                  <div class="col-md-6">
                                    <mat-form-field appearance="outline" class="w-100">
                                      <input matInput formControlName="othersMail"
                                             [value]="yourInfoForm.value.otherChecked ? yourInfoForm.value.othersMail : null"
                                             [email]="true">
                                    </mat-form-field>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      
                          <div class="col-lg-4 col-sm-12 pt-5">
                            <label for="agree">
                              <input type="checkbox" id="agree" formControlName="smsChecked" required> 
                              By checking this box, I agree to receive SMS messages about my quote request from Spekwo at the phone number(s) provided above. The SMS Frequency may vary. Data rates may apply. Text HELP TO 704-626-1776 for assistance. Reply Stop to Opt out of receiving SMS messages. Visit our
                              <a href="/privacy-policy" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Privacy Policy</a> and
                              <a href="/terms-and-conditions" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Terms of Service</a>.
                            </label>
                          </div>
                        </div>
                      </div>
                                        
                </div>
                

                <div>
                    <button mat-button mat-raised-button color="primary" class="mr-4 order-default-btn"
                        matStepperPrevious>Back</button>
                    <button mat-button mat-raised-button color="primary" class="mr-4 order-default-btn" matStepperNext
                        [disabled]="!yourInfoForm.valid">Next</button>
                </div>

            </form>
        </mat-step>
        <mat-step [stepControl]="isSpecificItem ? quotedForm && specificItemForm :  quotedForm"
            *ngIf="isFullSpekwo|| isSpecificItem" label={{productContent.billingForm.formStepper.whatQuote}}>
            <form [formGroup]="quotedForm">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-sm-12">
                            <h6>{{productContent.billingForm.formFields.note}}</h6>
                            <ol>
                                <li>{{productContent.billingForm.formFields.note1}}</li>
                                <li>{{productContent.billingForm.formFields.note2}}</li>
                            </ol>
                        </div>

                        <p [style.color]="'red'" *ngIf="filesUploadAlert">{{filesUploadAlert}}</p>

                        <div class="container" [ngClass]="{'col-lg-12 col-md-12 col-sm-12': true,}">
                            <div class="row">
                                <div class="form-group">
                                    <mat-checkbox class="col-lg-3 col-sm-3" formControlName="homeInsChecked"
                                        (change)="reportChanges('homeInsReport',$event)">{{productContent.billingForm.formFields.homeIns}}</mat-checkbox>
                                    <input class="col-lg-6 col-sm-6" formControlName="homeInsReport" required
                                        type="file" (change)="onFileSelected('homeInsReport',$event)"
                                        accept="application/pdf" (click)="fileInputClick('homeInsChecked',$event)">
                                </div>
                                <div class="form-group">
                                    <mat-checkbox class="col-lg-3 col-sm-3" formControlName="ddraChecked"
                                        (change)="reportChanges('ddraReport',$event)">{{productContent.billingForm.formFields.ddra}}</mat-checkbox>
                                    <input class="col-lg-6 col-sm-6" formControlName="ddraReport" required type="file"
                                        (change)="onFileSelected('ddraReport',$event)" accept="application/pdf"
                                        (click)="fileInputClick('ddraChecked',$event)">
                                </div>
                                <div class="form-group">
                                    <mat-checkbox class="col-lg-3 col-sm-3" formControlName="wdoReportChecked"
                                        (change)="reportChanges('wdoReport',$event)">{{productContent.billingForm.formFields.wdoReport}}</mat-checkbox>
                                    <input class="col-lg-6 col-sm-6" formControlName="wdoReport" required type="file"
                                        (change)="onFileSelected('wdoReport',$event)" accept="application/pdf"
                                        (click)="fileInputClick('wdoReportChecked',$event)">
                                </div>
                                <div class="form-group">
                                    <mat-checkbox class="col-lg-3 col-sm-3" formControlName="structEngChecked"
                                        (change)="reportChanges('structEngReport',$event)">{{productContent.billingForm.formFields.strctEng}}</mat-checkbox>
                                    <input class="col-lg-6 col-sm-6" formControlName="structEngReport" required
                                        type="file" (change)="onFileSelected('structEngReport',$event)"
                                        accept="application/pdf" (click)="fileInputClick('structEngChecked',$event)">
                                </div>
                                <div class="form-group">
                                    <mat-checkbox class="col-lg-3 col-sm-3" formControlName="wellReportChecked"
                                        (change)="reportChanges('wellReport',$event)">{{productContent.billingForm.formFields.wellReport}}</mat-checkbox>
                                    <input class="col-lg-6 col-sm-6" formControlName="wellReport" required type="file"
                                        (change)="onFileSelected('wellReport',$event)" accept="application/pdf"
                                        (click)="fileInputClick('wellReportChecked',$event)">
                                </div>
                            </div>
                        </div>
                        <div class="container" [ngClass]="{'col-lg-12 col-md-12 col-sm-12': true}">
                            <h6 *ngIf="isSpecificItem">{{productContent.billingForm.formFields.notesDesc}}
                            </h6>
                            <div class="row" *ngIf="isSpecificItem">
                                <mat-form-field [style.fontSize]="14" appearance="outline">
                                    <mat-label>{{productContent.billingForm.formFields.notes}}</mat-label>
                                    <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                        cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" formControlName="notes">
                                    </textarea>
                                </mat-form-field>
                            </div>
                            <h6 *ngIf="isFullSpekwo">{{productContent.billingForm.formFields.notesDescAddFull}}</h6>
                            <p *ngIf="isFullSpekwo">{{productContent.billingForm.formFields.notesDescAddFullAns}}</p>
                            <h6 *ngIf="isSpecificItem">{{productContent.billingForm.formFields.notesDescAddSpecific}}
                            </h6>
                            <div class="row additional-forms" *ngIf="isFullSpekwo">
                                <form [formGroup]="additionaProductlForm">
                                    <div formGroupName="bodySideDes">
                                        <div formArrayName="PRE">
                                            <div *ngFor="let phase of addProductAdditionals.controls; let i = index;"
                                                [formGroupName]="i">
                                                <mat-form-field appearance="outline"
                                                    [ngClass]="{'col-lg-2 col-sm-2 pl-4 pr-4': isFullSpekwo, 'col-lg-9 col-sm-2 pl-4 pr-4': isSpecificItem}">
                                                    <mat-label>{{productContent.billingForm.formFields.item}}</mat-label>
                                                    <input matInput type="text" formControlName="id" />
                                                </mat-form-field>

                                                <mat-form-field appearance="outline" class="col-lg-9 col-sm-9 pl-4 pr-4"
                                                    *ngIf="!isSpecificItem">
                                                    <mat-label>{{productContent.billingForm.formFields.description}}</mat-label>
                                                    <input matInput type="text" formControlName="description" />
                                                </mat-form-field>
                                                <button mat-icon-button (click)="removeAdditionalDesc(i)"
                                                    matTooltip="Remove Code" class="col-lg-1 col-sm-1 pl-4 pr-4">
                                                    <mat-icon aria-label="Close">highlight_off</mat-icon>
                                                </button>
                                                <button mat-icon-button (click)="addMoreAdditionalDesc()"
                                                    *ngIf=" i + 1 == additionaProductlForm.value.bodySideDes.PRE.length && !isItemCompleted"
                                                    matTooltip="Add License Types" class="col-lg-1 col-sm-1">
                                                    <mat-icon aria-label="Close">add_circle_outline</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div *ngIf="isSpecificItem" [formGroup]="specificItemForm">
                                <h6>{{productContent.billingForm.formFields.upto40}}</h6>
                                <input matInput size="7" class="spec-items" formControlName="item1" placeholder="item#"
                                    required />
                                <input matInput size="7" class="spec-items" formControlName="item2"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item3"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item4"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item5"
                                    placeholder="item#" />

                                <input matInput size="7" class="spec-items" formControlName="item6"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item7"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item8"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item9"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item10"
                                    placeholder="item#" />

                                <input matInput size="7" class="spec-items" formControlName="item11"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item12"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item13"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item14"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item15"
                                    placeholder="item#" />

                                <input matInput size="7" class="spec-items" formControlName="item16"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item17"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item18"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item19"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item20"
                                    placeholder="item#" />

                                <input matInput size="7" class="spec-items" formControlName="item21"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item22"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item23"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item24"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item25"
                                    placeholder="item#" />

                                <input matInput size="7" class="spec-items" formControlName="item26"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item27"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item28"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item29"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item30"
                                    placeholder="item#" />

                                <input matInput size="7" class="spec-items" formControlName="item31"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item32"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item33"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item34"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item35"
                                    placeholder="item#" />

                                <input matInput size="7" class="spec-items" formControlName="item36"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item37"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item38"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item39"
                                    placeholder="item#" />
                                <input matInput size="7" class="spec-items" formControlName="item40"
                                    placeholder="item#" />
                            </div>
                        </div>

                    </div>

                </div>

                <div>
                    <button mat-button mat-raised-button color="primary" class="mr-4" matStepperPrevious>Back</button>
                    <button mat-button mat-raised-button color="primary" class="mr-4" [matStepperNext]="false"
                        [disabled]="!quotedForm.valid" (click)="quoteFormNext()">Next</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="preListForm" *ngIf="isPreListItem"
            label={{productContent.billingForm.formStepper.whatQuote}}>
            <form [formGroup]="preListForm">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-sm-12">
                            <h6>{{productContent.billingForm.formFields.note}}</h6>
                            <ol>
                                <li>{{productContent.billingForm.formFields.note1}}</li>
                                <li>{{productContent.billingForm.formFields.note2}}</li>
                            </ol>
                        </div>

                        <p [style.color]="'red'" *ngIf="filesUploadAlert">{{filesUploadAlert}}</p>

                        <div class="container col-lg-12 col-md-12 col-sm-12">
                            <div class="row">
                                <div class="form-group">
                                    <mat-checkbox class="col-lg-3 col-sm-3" formControlName="homeInsChecked"
                                        (change)="fileCheckBoxChanges('homeInsReport',$event)">{{productContent.billingForm.formFields.homeIns}}</mat-checkbox>
                                    <input class="col-lg-6 col-sm-6" formControlName="homeInsReport" type="file"
                                        (change)="preListFileSelect('homeInsReport',$event)" accept="application/pdf"
                                        (click)="filePreListInputClick('homeInsChecked',$event)">
                                </div>
                            </div>
                        </div>
                        <h5 class="no-home-ins">{{productContent.preListSpekwo.noHomeInsDesc}}</h5>
                        <!-- <h6><u>{{productContent.preListSpekwo.uploadPicsTitle}}</u></h6> -->
                        <h6><u>{{productContent.preListSpekwo.preListInst}}</u></h6>
                        <div>
                            <ol>
                                <li>
                                    <h6>{{productContent.preListSpekwo.preListInst1}}</h6>
                                </li>
                                <li>
                                    <h6>{{productContent.preListSpekwo.preListInst2}}</h6>
                                </li>
                            </ol>
                        </div>
                        <p [style.color]="'red'" *ngIf="picUploadError">{{picUploadError}}</p>
                        <form [formGroup]="additionaProductlForm">
                            <div class="row additional-forms">
                                <div formGroupName="bodySideDes">
                                    <div formArrayName="PRE">
                                        <div *ngFor="let phase of addProductAdditionals.controls; let i = index;"
                                            [formGroupName]="i" class="form-group-name-view">
                                            <mat-form-field appearance="outline" class="col-lg-2 col-sm-12 pl-4 pr-4">
                                                <mat-label>{{productContent.billingForm.formFields.item}}</mat-label>
                                                <input matInput type="text" formControlName="id" readonly />
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" class="col-lg-4 col-sm-12 pl-4 pr-4"
                                                *ngIf="!isSpecificItem">
                                                <mat-label>{{productContent.billingForm.formFields.description}}</mat-label>
                                                <input matInput type="text" formControlName="description" />
                                            </mat-form-field>
                                            <input class="col-lg-3 col-sm-12 pl-4 pr-4" formControlName="image" required
                                                type="file" (change)="onPicsFileSelected('image',$event, i)"
                                                accept="image/png, image/jpeg" multiple />
                                            <button mat-raised-button color="primary" class="pl-4 pr-4"
                                                (click)="viewUploadPics(i)"
                                                [disabled]="!(base64UrlList[i] && base64UrlList[i].length)">View</button>
                                            <button mat-icon-button (click)="removeAdditionalDesc(i)"
                                                matTooltip="Remove Code" class="col-lg-1 col-sm-12 pl-4 pr-4">
                                                <mat-icon aria-label="Close">highlight_off</mat-icon>
                                            </button>
                                            <button mat-icon-button (click)="addMoreAdditionalDesc()"
                                                *ngIf=" i + 1 == additionaProductlForm.value.bodySideDes.PRE.length && !isItemCompleted"
                                                matTooltip="Add License Types" class="col-lg-1 col-sm-12 pl-4 pr-4">
                                                <mat-icon aria-label="Close">add_circle_outline</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <mat-checkbox class="col-lg-12 col-sm-12" formControlName="moreThanChecked"><span
                                class="more-ten-pic">{{productContent.preListSpekwo.moreThanTen}}</span></mat-checkbox>

                    </div>
                </div>
                <div>
                    <button mat-button mat-raised-button color="primary" class="mr-4" matStepperPrevious>Back</button>
                    <button mat-button mat-raised-button color="primary" class="mr-4" matStepperNext
                        [disabled]="!preListForm.valid">Next</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="additionalPics" *ngIf="isPreListItem && this.preListForm.value.moreThanChecked"
            label={{productContent.billingForm.formStepper.moreThanTen}}>
            <form [formGroup]="additionalPics">
                <div class="container">
                    <div class="row">
                        <p class="col-lg-12 col-sm-12">{{productContent.markets.desc}}</p>
                        <mat-form-field>
                            <textarea matInput name="questionText" cols="35" rows="4"
                                formControlName="projectHighlights"
                                [placeholder]="productContent.markets.projectsHighlight" required></textarea>
                        </mat-form-field>

                        <div class="row">
                            <p class="col-lg-4 col-sm-12 home-market">{{productContent.markets.homeMarket}}</p>
                            <mat-form-field class="col-lg-3 col-sm-12">
                                <mat-label>Choose a date</mat-label>
                                <input formControlName="date" matInput [matDatepicker]="picker" required>
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <p class="col-lg-12 col-sm-12 home-market">{{productContent.markets.meetingInfo}}</p>
                            <mat-form-field appearance="outline" class="col-lg-3 col-sm-3">
                                <mat-label>{{productContent.billingForm.formFields.name}}</mat-label>
                                <input matInput formControlName="name" required>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="col-lg-3 col-sm-3">
                                <mat-label>{{productContent.billingForm.formFields.email}}</mat-label>
                                <input matInput formControlName="email" required email>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="col-lg-3 col-sm-3">
                                <mat-label>{{productContent.billingForm.formFields.phone}}</mat-label>
                                <input matInput formControlName="phone" required mask="(000)000-0000">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div>
                    <button mat-button mat-raised-button color="primary" class="mr-4" matStepperPrevious>Back</button>
                    <button mat-button mat-raised-button color="primary" class="mr-4" matStepperNext
                        [disabled]="!additionalPics.valid">Next</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="paymentform">
            <ng-template matStepLabel>{{productContent.billingForm.formStepper.orderAndSubmit}}</ng-template>
            <!-- Start Solution Estimate hours Area -->
            <div class="appointment-area pt-20 pb-20">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12 col-md-12">
                            <spekwo-ins-hours (selectedHourEmit)="selectedHourEvent($event)"
                                [productPrice]="currentProduct.productPrice"></spekwo-ins-hours>
                        </div>
                    </div>
                </div>
            </div>
            <p [style.color]="'red'" *ngIf="estimateHourErr">{{estimateHourErr}}</p>
            <form [formGroup]="paymentform">
                <div class="container">
                    <!-- <div>
                        <p>
                            <b>
                                "We are going through a software upgrade and can only process payments over the
                                phone.
                                Please fill out Your email address in the "invoice to client" box and click submit.
                                We
                                will contact you to complete payment. No worries your quote is underway the moment
                                you
                                submit this. You can call our office at <a href="#">866.646.0200</a> if you have any
                                other questions."
                            </b>
                        </p>
                    </div> -->
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12"
                            *ngFor="let payOption of productContent.payingOptions; let i = index;"
                            [value]="payOption.value" (click)="selectPayingOptions(payOption.key, i)">
                            <div class="form-group card" [ngClass]="{'highlight': selectedPayIndex === i}">
                                <p class="role">{{payOption.value}}{{paymentform.value.totalPrice | number: '1.2-2'}}
                                </p>
                                <input type="hidden" name="{{payOption.key}}" id="{{payOption.key}}"
                                    formControlName="payingOption">
                            </div>
                        </div>

                        <div *ngIf="isClientPay" class="row center main-form-field-view">
                            <div class="col-lg-6 pl-4 pr-4">
                                <mat-form-field appearance="outline" class="col-lg-12 col-sm-12">
                                    <mat-label>{{productContent.billingForm.formFields.clientEmail}}</mat-label>
                                    <input matInput formControlName="clientEmail"
                                        placeholder={{productContent.billingForm.formFields.clientEmail}}
                                        [email]="true" />
                                </mat-form-field>
                                <p>{{productContent.billingForm.formFields.clientPayNote}}</p>
                            </div>
                            <div class="col-lg-4 col-sm-12 pl-4 pr-4 mb-4">
                                <button mat-raised-button class="col-lg-12 col-sm-4  submit-btn" color="primary"
                                    [disabled]="!paymentform.valid || isLoading" (click)="submitOrder()">
                                    <i class="fa"
                                        [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i>{{productContent.billingForm.formFields.submitOrder}}</button>
                            </div>

                        </div>


                        <div *ngIf="isPayingOnMe" class="col-lg-12 col-sm-12">
                            <!-- <mat-form-field appearance="outline" class="col-lg-4 col-sm-4 pl-4 pr-4">
                                <mat-label>{{productContent.billingForm.formFields.nameOfCard}}</mat-label>
                                <input matInput formControlName="nameOfCard"
                                    placeholder={{productContent.billingForm.formFields.nameOfCard}} />
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="col-lg-4 col-sm-4 pl-4 pr-4">
                                <mat-label>{{productContent.billingForm.formFields.cardNumber}}</mat-label>
                                <input matInput formControlName="cardNumber"
                                    placeholder={{productContent.billingForm.formFields.cardNumber}}
                                    mask="0000-0000-0000-0000" />
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="col-lg-2 col-sm-2 pl-4 pr-4">
                                <mat-label>{{productContent.billingForm.formFields.expDateFormat}}</mat-label>
                                <input matInput formControlName="expireDate"
                                    placeholder={{productContent.billingForm.formFields.expDate}} mask="00/0000" />
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="col-lg-2 col-sm-2 pl-4 pr-4">
                                <mat-label>{{productContent.billingForm.formFields.cvcCode}}</mat-label>
                                <input matInput formControlName="cvcCode"
                                    placeholder={{productContent.billingForm.formFields.cvcCode}} mask="000" />
                            </mat-form-field> -->

                            <mat-form-field appearance="outline" class="col-lg-4 col-sm-4 pl-4 pr-4">
                                <mat-label>{{productContent.billingForm.formFields.promoCode}}</mat-label>
                                <input matInput formControlName="promoCode"
                                    placeholder={{productContent.billingForm.formFields.promoCode}} />
                            </mat-form-field>

                            <button mat-raised-button color="primary" class="col-lg-3 col-sm-3 ml-4 mr-4 submit-btn"
                                [disabled]="isCouponApplied"
                                (click)="applyPromoCode()">{{productContent.billingForm.formFields.applyPromo}}</button>

                            <button mat-raised-button class="col-lg-3 col-sm-3 ml-4 mr-4 submit-btn" color="primary"
                                (click)="submitOrder()">
                                <i class="fa" [ngClass]="{ 'fa-check': !isLoading}"></i>
                                {{productContent.billingForm.formFields.submitOrder}}</button>
                        </div>
                    </div>
                </div>
            </form>



            <!--  Solution Estimate hours End -->
            <div>
                <button mat-button mat-raised-button color="primary" class="mr-4 mt-4" matStepperPrevious>Back</button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</div>
<!-- Appointment Area End -->

<order-submit *ngIf="isSubmitted" [estimateHour]="paymentform.value.estimateHour" [dueDate]="dueDate"
    [orderId]="orderId" [sku]="currentProduct.sku" [productName]="paymentform.value.productName"
    [propertyAddress]="addressForm.value.propertyAddress"></order-submit>