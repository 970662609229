import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-style-one',
  templateUrl: './services-style-one.component.html',
  styleUrls: ['./services-style-one.component.scss']
})
export class ServicesStyleOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
